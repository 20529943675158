/*!
 * Bootstrap Select v1.5.0 (https://github.com/biggora/bootstrap-select)
 * Copyright 2013-2014 Silvio Moreto
 * Licensed under MIT (https://github.com/biggora/bootstrap-select/blob/master/LICENSE)
 * Plugin demo http://plugins.upbootstrap.com/bootstrap-select
 */
.bootstrap-select, .bootstrap-select:not([class*=span]) {
  width: 220px
}

.bootstrap-select .dropdown-menu .no-results {
  display: block;
  padding: 5px 10px 3px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap
}

.bootstrap-select .dropdown-menu > .selected > a, .bootstrap-select .dropdown-menu > .selected > a:hover, .bootstrap-select .dropdown-menu > .selected > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #428bca
}

.bootstrap-select.btn-group, .bootstrap-select.btn-group[class*=span] {
  float: none;
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 0
}

.bootstrap-select.btn-group[class*=span] .btn {
  width: 100%
}

.bootstrap-select.btn-group .btn .filter-option {
  overflow: hidden;
  position: absolute;
  left: 12px;
  right: 25px;
  text-align: left
}

.bootstrap-select.btn-group .btn .caret {
  position: absolute;
  right: 12px;
  top: 15px
}

.bootstrap-select.btn-group > .disabled, .bootstrap-select.btn-group .dropdown-menu li.disabled > a {
  cursor: not-allowed
}

.bootstrap-select.btn-group > .disabled:focus {
  outline: 0 !important
}

.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.bootstrap-select.btn-group .dropdown-menu .label {
  margin-left: -10px;
  line-height: 20px
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  border: 0;
  padding: 0;
  margin: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none
}

.bootstrap-select.btn-group .dropdown-menu dt {
  display: block;
  padding: 3px 20px;
  cursor: default
}

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative
}

.bootstrap-select.btn-group .dropdown-menu li > .opt {
  padding-left: 35px
}

.bootstrap-select.btn-group .dropdown-menu li > a {
  min-height: 20px;
  cursor: pointer
}

.bootstrap-select.btn-group .dropdown-menu li > a .check-mark {
  display: none
}

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: .5em
}

.bootstrap-select.btn-group .dropdown-menu li > dt small {
  font-weight: 400
}

.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:hover small, .bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:focus small {
  color: #999
}

.bootstrap-select.btn-group .dropdown-menu .bootstrap-select-searchbox {
  padding: 1px 7px 5px
}

.bootstrap-select.btn-group .filter-option .label {
  line-height: 20px
}

.bootstrap-select.btn-group .div-contain {
  overflow: hidden
}

.bootstrap-select.btn-group.show-tick .dropdown-menu .selected a .check-mark {
  display: inline-block;
  position: absolute;
  right: 15px;
  margin-top: 2.5px
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a .text {
  margin-right: 34px
}

.bootstrap-select > .btn {
  width: 100%
}

.bootstrap-select > .btn:focus {
  outline: thin dotted #333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px
}

.bootstrap-select .show-menu-arrow.open > .dropdown-toggle:before, .bootstrap-select .show-menu-arrow.open > .dropdown-toggle:after {
  display: block
}

.bootstrap-select .show-menu-arrow.open > .btn {
  z-index: 1000+1
}

.bootstrap-select .show-menu-arrow .dropdown-toggle:before, .bootstrap-select .show-menu-arrow .dropdown-toggle:after {
  display: none
}

.bootstrap-select .show-menu-arrow .dropdown-toggle:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: #ccc;
  position: absolute;
  bottom: -4px;
  left: 9px
}

.bootstrap-select .show-menu-arrow .dropdown-toggleafter {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  bottom: -4px;
  left: 10px
}

.bootstrap-select .show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid #ccc;
  border-bottom: 0;
  border-top-color: #000
}

.bootstrap-select .show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid #fff;
  border-bottom: 0
}

.bootstrap-select .show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto
}

.bootstrap-select .show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto
}

.form-search .bootstrap-select.btn-group, .form-inline .bootstrap-select.btn-group, .form-horizontal .bootstrap-select.btn-group {
  margin-bottom: 0
}

.bootstrap-select.btn-group.pull-right, .bootstrap-select.btn-group[class*=span].pull-right, .row-fluid .bootstrap-select.btn-group[class*=span].pull-right, .row .bootstrap-select.btn-group[class*=span].pull-right {
  float: right
}

.input-append .bootstrap-select.btn-group {
  margin-left: -1px
}

.input-prepend .bootstrap-select.btn-group {
  margin-right: -1px
}