.contact__list {
  display: flex;
  flex-direction: column;

  .list__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;

    i {
      margin-right: 10px;
      font-size: 18px;
      color: $primColorNigga;
    }

    .item__name {
      margin-right: 10px;
    }
  }
}