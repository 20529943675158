%glass {
  backdrop-filter: blur(25px) saturate(180%);
  background: repeat url('/assets/template/images/ui/noise.png'), rgba(#fff, .75) !important;
  box-shadow: 2px 2px 5px rgba(#000, .35);
  opacity: 1;
  background-blend-mode: multiply;
  border-radius: 7px;
}

.btn-primary {
  background-color: $primColor;
}

.btn-primary:hover, .btn-primary.focus, .btn-primary:focus {
  background-color: $primColorNigga;
}

.navbar-area {
  .navbar-wrapper {
    position: relative;
  }

  .miniCart {
    position: absolute;
    right: 0;
    top: -110px;
  }
}

.navbar-area.sticky {
  .miniCart {
    top: 5px;
  }
}