.blog-content {
  h1, .h1 {
    font-size: 48px !important;
  }

  h2, .h2 {
    font-size: 36px !important;
  }

  h3, .h3 {
    font-size: 28px !important;
  }

  h4, .h4 {
    font-size: 22px !important;
  }

  h5, .h5 {
    font-size: 18px !important;
  }

  h6, .h6 {
    font-size: 16px !important;
  }
}