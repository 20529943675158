.miniCart {
 .cart-empty {
   display: flex;
   flex-direction: column;
   align-items: center;
   border-bottom: 1px solid #f2f4f6;
   padding: 15px 15px 25px;

   .icon {
     font-size: 36px;
   }
   .text {
     font-size: 16px;
   }
 }
}