.jGrowl {
  z-index: 9999;
  color: #fff;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: fixed
}

.jGrowl.top-left {
  left: 0;
  top: 0
}

.jGrowl.top-right {
  right: 0;
  top: 0
}

.jGrowl.bottom-left {
  left: 0;
  bottom: 0
}

.jGrowl.bottom-right {
  right: 0;
  bottom: 0
}

.jGrowl.center {
  top: 0;
  width: 50%;
  left: 25%
}

.jGrowl.center .jGrowl-closer, .jGrowl.center .jGrowl-notification {
  margin-left: auto;
  margin-right: auto
}

.jGrowl-notification {
  background-color: #000;
  opacity: .9;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=(0.9*100));
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=(0.9*100));
  zoom: 1;
  width: 250px;
  padding: 10px;
  margin: 10px;
  text-align: left;
  display: none;
  border-radius: 5px;
  min-height: 40px
}

.jGrowl-notification .ui-state-highlight, .jGrowl-notification .ui-widget-content .ui-state-highlight, .jGrowl-notification .ui-widget-header .ui-state-highlight {
  border: 1px solid #000;
  background: #000;
  color: #fff
}

.jGrowl-notification .jGrowl-header {
  font-weight: 700;
  font-size: .85em
}

.jGrowl-notification .jGrowl-close {
  background-color: transparent;
  color: inherit;
  border: none;
  z-index: 99;
  float: right;
  font-weight: 700;
  font-size: 1em;
  cursor: pointer
}

.jGrowl-closer {
  background-color: #000;
  opacity: .9;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=(0.9*100));
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=(0.9*100));
  zoom: 1;
  width: 250px;
  padding: 10px;
  margin: 10px;
  display: none;
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  font-size: .9em;
  font-weight: 700;
  text-align: center
}

.jGrowl-closer .ui-state-highlight, .jGrowl-closer .ui-widget-content .ui-state-highlight, .jGrowl-closer .ui-widget-header .ui-state-highlight {
  border: 1px solid #000;
  background: #000;
  color: #fff
}

@media print {
  .jGrowl {
    display: none
  }
}