@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./fonts/arimo/arimo-v16-latin_cyrillic-regular.eot');
  src: local(""), url('./fonts/arimo/arimo-v16-latin_cyrillic-regular.eot?#iefix') format("embedded-opentype"), url('./fonts/arimo/arimo-v16-latin_cyrillic-regular.woff2') format("woff2"), url('./fonts/arimo/arimo-v16-latin_cyrillic-regular.woff') format("woff"), url('./fonts/arimo/arimo-v16-latin_cyrillic-regular.ttf') format("truetype"), url('./fonts/arimo/arimo-v16-latin_cyrillic-regular.svg#Arimo') format("svg");
}

@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./fonts/arimo/arimo-v16-latin_cyrillic-700.eot');
  src: local(""), url('./fonts/arimo/arimo-v16-latin_cyrillic-700.eot?#iefix') format("embedded-opentype"), url('./fonts/arimo/arimo-v16-latin_cyrillic-700.woff2') format("woff2"), url('./fonts/arimo/arimo-v16-latin_cyrillic-700.woff') format("woff"), url('./fonts/arimo/arimo-v16-latin_cyrillic-700.ttf') format("truetype"), url('./fonts/arimo/arimo-v16-latin_cyrillic-700.svg#Arimo') format("svg");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./fonts/montserrat/montserrat-v15-latin_cyrillic-300.eot');
  src: local(""), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-300.eot?#iefix') format("embedded-opentype"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-300.woff2') format("woff2"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-300.woff') format("woff"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-300.ttf') format("truetype"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-300.svg#Montserrat') format("svg");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./fonts/montserrat/montserrat-v15-latin_cyrillic-regular.eot');
  src: local(""), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-regular.eot?#iefix') format("embedded-opentype"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-regular.woff2') format("woff2"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-regular.woff') format("woff"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-regular.ttf') format("truetype"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-regular.svg#Montserrat') format("svg");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('./fonts/montserrat/montserrat-v15-latin_cyrillic-500.eot');
  src: local(""), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-500.eot?#iefix') format("embedded-opentype"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-500.woff2') format("woff2"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-500.woff') format("woff"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-500.ttf') format("truetype"), url('./fonts/montserrat/montserrat-v15-latin_cyrillic-500.svg#Montserrat') format("svg");
}

body {
  font-family: Arimo, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #666;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a:focus, button:focus, input:focus, textarea:focus {
  text-decoration: none;
  outline: 0;
}

a {
  &:focus, &:hover {
    text-decoration: none;
  }

  display: inline-block;
}

i, span {
  display: inline-block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: #333;
  margin: 0;
}

h1, .h1 {
  font-size: 48px;
}

h2, .h2 {
  font-size: 36px;
}

h3, .h3 {
  font-size: 28px;
}

h4, .h4 {
  font-size: 22px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #666;
  margin: 0;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.open > .dropdown-menu {
  display: block;
}

.wrapper-bg {
  background-color: #f2f4f6;
}

.shop-single-content .product-btn .main-btn{
  margin-top: 5px !important;
}

.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none; 
  border: 0;
  padding: 0 40px;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  transition: all .4s linear;
  background-color: $primColor;

  i {
    margin-right: 5px;
  }

  &:hover {
    background-color: #333;
    color: #fff;
  }
}

.slick-slide {
  outline: 0;
}

.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;

  &.active {
    height: auto;
    visibility: visible;
    opacity: 1;
    overflow: visible;
  }
}

.overlay {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  z-index: 99;
  visibility: visible;
  transition: all .3s linear;
  opacity: 0;
  visibility: hidden;

  &.open {
    opacity: .7;
    visibility: visible;
  }
}



.checkbox {
  input[type=checkbox] {
    display: none;

    + label {
      position: relative;
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 400;
      color: #666;

      span {
        width: 15px;
        height: 15px;
        border: 1px solid #666;
        position: relative;
        border-radius: 3px;
        top: 5px;
        margin-right: 5px;
        transition: all 0.3s linear;

        &::before {
          content: '\f00c';
          font-family: "Font Awesome 5 Pro";
          font-weight: 400;
          font-size: 12px;
          color: #222;
          transition: all .3s linear;
          position: relative;
          top: -5px;
          left: 1px;
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &:checked + label span::before {
      color: #333;
      opacity: 1;
      visibility: visible;
    }
  }

  p {
    display: inline-block;
    font-size: 15px;
    color: #333;
  }
}

.pagination {
  flex-wrap: wrap;
  justify-content: center;
} 

.pagination li {
  a, span {
    /*width: 30px;
    height: 30px;
    line-height: 28px;*/
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #333;
    font-size: 14px;
    transition: all 0.3s linear;
  }

  a {
    &.active, &:hover {
      background-color: #333;
      color: #fff;
      border-color: #333;
    }
  }

  span {
    background-color: #333;
    color: #fff;
    border-color: #333;
  }
}

.single-form {
  margin-top: 30px;

  input, select, textarea {
    width: 100%;
    font-size: 15px;
    height: 50px;
    color: #222;
    border: 1px solid #e6e6e6;
    padding: 0 15px;
    transition: all 0.3s linear;
  }

  input:focus, select:focus, textarea:focus {
    border-color: $primColor;
  }

  input + input, select + input {
    margin-top: 30px;
  }

  textarea {
    + input {
      margin-top: 30px;
    }

    height: 180px;
    padding-top: 10px;
    resize: none;
  }
}

.single-select2 {
  margin-top: 25px;

  label {
    font-size: 13px;
    color: #666;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .form-select2 {
    height: 50px;
    border-radius: 3px;
    border: 1px solid #e6e6e6;

    .select2-container {
      display: block;
      width: 100% !important;

      .selection {
        display: block;
        width: 100%;

        .select2-selection {
          width: 100%;
          border-radius: 0;
          border: 0;

          &:focus {
            outline: 0;
          }

          .select2-selection__rendered {
            line-height: 48px;
            padding-left: 15px;
            font-size: 15px;
            color: #333;
          }

          .select2-selection__arrow {
            height: 48px;
            right: 10px;
          }
        }
      }
    }
  }
}

.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option {
  font-size: 14px;
}

.header-area {
  background-color: $primColor;
}

.header-top-wrapper {
  border-bottom: 1px solid #2695ca;
  padding: 5px 0 15px;
}

.header-top-left {
  padding-top: 10px;

  .support {
    color: #fff;
    font-size: 14px;
    font-weight: 400;

    i {
      margin-right: 10px;
    }
  }
}

@media (max-width: 767px) {
  .header-top-left .support {
    font-size: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-top-left .support {
    font-size: 14px;
  }
}

.header-top-right {
  padding-top: 10px;

  .header-meta > li {
    display: inline-block;

    + li {
      position: relative;

      &::before {
        content: '|';
        display: inline-block;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 300;
        font-size: 16px;
      }
    }

    .dropdown {
      padding: 0 20px;

      .dropdown-toggle {
        background: 0 0;
        border: 0;
        color: #fff;
        font-size: 14px;
        font-weight: 400;

        &::after {
          width: 7px;
          height: 7px;
          border: 0;
          border-bottom: 1px solid #fff;
          border-left: 1px solid #fff;
          transform: rotate(-45deg);
          margin-left: 10px;
        }
      }

      .dropdown-menu {
        border: 0;
        padding: 0;
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);
        background: #fff;
        padding: 8px;
        width: 150px;
        min-width: inherit;
        border-radius: 0;
        top: 37px !important;
        transform: translate3d(0, 0, 0) !important;

        li a {
          color: #777;
          text-decoration: none;
          font-size: 12px;
          padding: 0 10px;
          line-height: 30px;
          transition: all .3s linear;
          display: block;

          &:hover {
            color: $primColor;
          }
        }
      }
    }

    &:first-child .dropdown {
      padding-left: 0;
    }

    &:last-child .dropdown {
      padding-right: 0;

      .dropdown-menu {
        left: auto !important;
        right: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .header-top-right .header-meta > li .dropdown {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-top-right .header-meta > li .dropdown {
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .header-top-right .header-meta > li .dropdown .dropdown-toggle {
    font-size: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-top-right .header-meta > li .dropdown .dropdown-toggle {
    font-size: 14px;
  }
}

.header-middle {
  padding: 45px 0 75px;
  position: relative;
}

.header-search {
  .search-form {
    position: relative;

    i {
      position: absolute;
      top: 18px;
      left: 18px;
      font-size: 16px;
    }

    input {
      width: 100%;
      padding-left: 50px;
      padding-right: 180px;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      color: #333;
      border: none;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .bootstrap-select {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      line-height: 50px;
      margin-bottom: 0;
      width: auto;
      background-color: #fff;

      .btn {
        position: relative;
        padding: 0 14px 0 5px;
        border-radius: 0;
        line-height: 24px;

        &::before {
          position: absolute;
          content: '';
          width: 1px;
          height: 18px;
          background-color: #333;
          top: 41%;
          left: 0;
          transform: translateY(-50%);
        }

        &:focus {
          outline: 0 !important;
          outline-offset: 0;
          box-shadow: none;
        }

        &::after {
          width: 7px;
          height: 7px;
          border: 0;
          border-bottom: 1px solid #333;
          border-left: 1px solid #333;
          transform: rotate(-45deg) translateY(-100%);
          margin-left: 20px;
        }

        .filter-option {
          position: relative;
          font-size: 15px;
          color: #333;
        }
      }

      > .dropdown-menu {
        padding: 0;
        border: 0;
        border-radius: 0;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        width: 180px;
        margin-top: 7px;

        li {
          a {
            display: block;
            padding: 0 20px;
            min-height: inherit;
            height: auto;
            line-height: 24px;
            font-size: 13px;
            color: #333;
          }

          &.selected a {
            color: #fff;
          }
        }
      }
    }
  }

  .search-btn button {
    height: 50px;
    background-color: #f0d43a;
    color: #333;
    width: 100px;
    border: 0;
    font-size: 16px;
    font-weight: 600;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 0.3s linear;

    .icon {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .header-search .search-form i {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-search .search-form i {
    left: 15px;
    top: 13px;
    font-size: 13px;
    display: block;
  }
}

@media (max-width: 767px) {
  .header-search .search-form input {
    height: 40px;
    padding-left: 10px;
    padding-right: 125px;
    font-size: 13px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-search .search-form input {
    height: 40px;
    padding-left: 40px;
    padding-right: 100px;
  }
}

@media (max-width: 767px) {
  .header-search .search-form .bootstrap-select {
    height: 40px;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .header-search .search-form .bootstrap-select .btn {
    padding: 0 5px 0 0;
  }
}

@media (max-width: 767px) {
  .header-search .search-form .bootstrap-select .btn .filter-option {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .header-search .search-btn button {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-search .search-btn button {
    width: 85px;
  }
}

@media (max-width: 767px) {
  .header-search .search-btn button .text {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-search .search-btn button .text {
    display: block;
  }
}

@media (max-width: 767px) {
  .header-search .search-btn button .icon {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-search .search-btn button .icon {
    display: none;
  }
}

.header-cart {
  text-align: right;

  .dropdown {
    display: inline-block;

    .cart {
      color: #fff;
      padding: 6px 0;

      i {
        font-size: 36px;
        line-height: 40px;
      }

      .cart-quantity {
        background: #f0d43a;
        margin: 0;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 100%;
        color: #333;
        padding: 0;
        font-size: 13px;
        bottom: 5px;
        left: 22px;
        position: absolute;
      }

      .cart-total {
        font-size: 18px;
        font-weight: 700;
        margin-left: 25px;
        vertical-align: 7px;
      }
    }

    .dropdown-menu {
      padding: 0 15px;
      width: 350px;
      border-radius: 0;
      left: auto !important;
      right: 0;
      border: 0;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      transform: translate3d(0, 0, 0) !important;
      top: 100% !important;

      .cart-items .single-mini-cart {
        padding: 20px;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .close {
          background-color: #777;
          color: #fff;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          border-radius: 50%;
          position: absolute;
          top: 19px;
          right: 10px;
          font-size: 12px;
        }

        .cart-thumb img {
          width: 98px;
        }

        .cart-content {
          padding-left: 20px;

          .product-name {
            .quantity, a {
              color: #9c9b9b;
              font-size: 15px;
            }
          }

          .product-atributes a {
            color: #777;
            font-size: 15px;
          }

          .price {
            font-size: 14px;
            font-weight: 600;
            color: #333;
            padding-top: 10px;
          }
        }
      }

      .cart-prices {
        padding: 10px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .cart-prices-line {
          display: flex;
          justify-content: space-between;
          padding: 7px 0;

          .value {
            font-size: 16px;
            color: #333;
            font-weight: 600;
            text-transform: uppercase;
          }

          .price {
            font-size: 16px;
            color: #333;
            font-weight: 600;
          }
        }
      }

      .cart-buttons {
        text-align: center;
        padding: 20px 0;

        .main-btn {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          padding: 0 25px;
          text-transform: uppercase;
          font-weight: 600;

          &:hover {
            background-color: #f0d43a;
            color: #fff;
          }
        }

        &.cart-buttons-2 .main-btn {
          background-color: #084a83;

          &:hover {
            background-color: #f0d43a;
            color: #fff;
          }
        }

        &.cart-buttons-3 .main-btn {
          background-color: #31aae8;

          &:hover {
            background-color: #f0d43a;
            color: #fff;
          }
        }

        &.cart-buttons-4 .main-btn {
          background-color: #42b099;

          &:hover {
            background-color: #ff6a61;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .cart i {
    font-size: 26px;
    line-height: 30px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .cart .cart-quantity {
    font-size: 10px;
    left: 12px;
    min-width: 10px;
    height: 13px;
    line-height: unset;
  }
  .back-to-top {
    bottom: 100px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .cart .cart-total {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .dropdown-menu {
    width: 280px;
    padding: 0 5px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .dropdown-menu .cart-items .single-mini-cart {
    padding-top: 10px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .dropdown-menu .cart-items .single-mini-cart .cart-thumb img {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .dropdown-menu .cart-items .single-mini-cart .cart-content .product-name .quantity {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .dropdown-menu .cart-items .single-mini-cart .cart-content .product-name a {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .dropdown-menu .cart-items .single-mini-cart .cart-content .product-atributes a {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .dropdown-menu .cart-items .single-mini-cart .cart-content .price {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .dropdown-menu .cart-prices .cart-prices-line .value {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .header-cart .dropdown .dropdown-menu .cart-prices .cart-prices-line .price {
    font-size: 14px;
  }
}

.header-mobile {
  padding: 15px 0;
}

@media (max-width: 767px) {
  .header-mobile-logo {
    width: 100px;
    margin: 0 auto;
  }
}

.header-menu-toggle {
  a span {
    width: 25px;
    height: 2px;
    background-color: #fff;
    display: block;
    margin: 5px 0;
  }

  &.toggle-2 a span {
    background-color: #333;
  }
}

.header-mobile-search {
  padding-top: 5px;
  padding-bottom: 40px;
}

.header-2 {
  background-color: #084a83;

  .header-top {
    background-color: #054074;

    .header-top-wrapper {
      border-bottom: 0;
    }
  }

  .header-middle {
    padding: 34px 0;
  }
}

.header-3 {
  background-color: #31aae8;

  .header-top {
    background-color: #fff;

    .header-top-wrapper {
      border-bottom: 0;

      .header-top-left .support {
        color: #333;
      }

      .header-top-right .header-meta > li {
        + li::before {
          color: #333;
        }

        .dropdown .dropdown-toggle {
          color: #333;

          &::after {
            border-bottom: 1px solid #333;
            border-left: 1px solid #333;
          }
        }
      }
    }
  }

  .header-middle {
    padding: 39px 0;
  }
}

.header-4 {
  background-color: #fff;

  .header-top {
    background-color: #f2f4f6;

    .header-top-wrapper {
      border-bottom: 0;

      .header-top-left .support {
        color: #333;
      }

      .header-top-right .header-meta > li {
        + li::before {
          color: #333;
        }

        .dropdown .dropdown-toggle {
          color: #333;

          &::after {
            border-bottom: 1px solid #333;
            border-left: 1px solid #333;
          }
        }
      }
    }
  }

  .header-middle {
    padding: 32px 0;
  }

  .header-search {
    .search-form {
      border: 1px solid #e6e6e6;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .search-btn button {
      height: 52px;
      background-color: #42b099;
      color: #fff;

      &:hover {
        background-color: #333;
      }
    }
  }

  .header-cart .dropdown {
    .cart {
      color: #333;

      .cart-quantity {
        background: #ff6a61;
        color: #fff;
      }
    }

    .dropdown-menu {
      border-top: 2px solid #42b099;
    }
  }
}

@media (max-width: 767px) {
  .header-4 .header-search .search-btn button {
    height: 42px;
  }
}

.navbar-area {
  margin-top: -30px;

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    margin-top: 0;
    animation: sticky 1s;
    box-shadow: 2px 4px 8px rgba(34, 34, 34, 0.25);
    background-color: $primColorNigga;
  }
}

@keyframes sticky {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.navbar-wrapper {
  background-color: $primColorNigga;
}

.navbar-category {
  position: relative;

  .category-title {
    font-size: 15px;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    color: #fff;
    background-color: $primColorNigger;
    line-height: 60px;
    padding: 0 25px;
    display: block;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    align-items: center;

    i {
      font-size: 20px;
      margin-right: 18px;
    }
  }

  .menu-items {
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 100%;
    z-index: 11;

    > li {
      position: relative;

      + li::before {
        position: absolute;
        content: '';
        height: 1px;
        background-color: #ededed;
        top: 0;
        left: 20px;
        right: 20px;
      }

      > a {
        position: relative;
        display: block;
        overflow: hidden;
        line-height: 32px;
        word-wrap: break-word;
        font-family: Arimo;
        color: #333;
        padding: 10px 20px;
        font-size: 15px;
        letter-spacing: .02em;
        transition: all 0.3s linear;

        img {
          margin-right: 15px;
        }

        > {
          i {
            display: block;
            float: right;
          }

          span > i {
            font-size: 20px;
            margin-right: 15px;
          }
        }
      }

      &:hover > a {
        color: $primColor;
      }

      .navbar-mega-sub-menu {
        position: absolute;
        top: 0;
        left: 115%;
        background-color: #fff;
        width: 550px;
        z-index: 99;
        box-shadow: 3px 6px 12px 0 rgba(0, 0, 0, 0.3);
        transition: all .3s linear;
        opacity: 0;
        visibility: hidden;
        padding: 0 30px 30px 30px;
        display: flex;
        flex-wrap: wrap;

        .mega-sub-item {
          width: 50%;
          padding-top: 30px;

          .title {
            color: #333;
            text-transform: uppercase;
            word-wrap: break-word;
            display: block;
            position: relative;
            font-family: Arimo;
            font-size: 18px;
            font-weight: 600;
            transition: all 0.3s linear;

            &:hover {
              color: $primColor;
            }
          }

          .mega-item {
            padding-top: 10px;

            li a {
              color: #333;
              display: block;
              line-height: 26px;
              font-size: 15px;
              font-weight: 400;
              transition: all 0.3s linear;

              &:hover {
                color: $primColor;
              }
            }
          }
        }
      }

      &:hover .navbar-mega-sub-menu {
        left: 100%;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-category .category-title {
    font-size: 14px;
  }
}

.sticky .navbar-category .menu-items {
  box-shadow: 2px 4px 8px rgba(34, 34, 34, 0.25);
}

.navbar-menu .main-menu {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  li {
    position: relative;

    a {
      line-height: 60px;
      display: block;
      color: #fff;
      letter-spacing: .05em;
      text-transform: uppercase;
      position: relative;
      z-index: 99;
      transition: all .3s linear;
      font-size: 14px;
      font-weight: 700;
      font-family: Montserrat, sans-serif;
      padding: 0 17px;

      &.active {
        color: #f0d43a;
      }
    }

    &:hover > a {
      color: #f0d43a;
    }

    .sub-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 200px;
      background-color: #fff;
      z-index: 11;
      padding: 10px 0;
      box-shadow: 3px 6px 12px 0 rgba(0, 0, 0, 0.3);
      transition: all .3s linear;
      opacity: 0;
      visibility: hidden;
      transform-style: preserve-3d;
      transform: rotateX(-90deg);

      li {
        a {
          color: #333;
          line-height: 30px;
          font-size: 13px;
          font-weight: 700;
          padding: 0 20px;

          i {
            float: right;
            display: block;
            line-height: 30px;
          }

          &.active {
            color: $primColor;
          }
        }

        .sub-menu {
          top: -10px;
          left: 100%;
          opacity: 0;
          visibility: hidden;
          transform-style: preserve-3d;
          transform: rotateX(-90deg);
        }

        &:hover {
          > a {
            color: $primColor;
          }

          .sub-menu {
            transform: rotateX(0);
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .mega-sub-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 870px;
      background-color: #fff;
      z-index: 11;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      box-shadow: 3px 6px 12px 0 rgba(0, 0, 0, 0.3);
      transition: all .3s linear;
      transform-style: preserve-3d;
      transform: rotateX(-90deg);
      opacity: 0;
      visibility: hidden;

      .mega-sub-item {
        width: 25%;

        .mega-title {
          color: #333;
          font-size: 15px;
          line-height: 30px;
          font-weight: 700;
          padding: 0;
          transition: all 0.3s linear;

          &:hover {
            color: $primColor;
          }
        }

        .mega-item {
          padding-top: 15px;

          li {
            a {
              color: #333;
              line-height: 28px;
              font-size: 13px;
              font-weight: 600;
              padding: 0;

              &.active {
                color: $primColor;
              }
            }

            &:hover a {
              color: $primColor;
            }
          }
        }
      }

      .custom-banner {
        width: 100%;
        overflow: hidden;
        padding-top: 20px;

        a {
          display: block;
          overflow: hidden;
          padding: 0;

          img {
            width: 100%;
            transition: all 0.3s linear;
          }
        }

        &:hover a img {
          transform: scale(1.05);
        }
      }
    }

    &:hover {
      .mega-sub-menu, .sub-menu {
        transform: rotateX(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-menu .main-menu li a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-menu .main-menu li {
    &:nth-last-child(1) .sub-menu li .sub-menu, &:nth-last-child(2) .sub-menu li .sub-menu, &:nth-last-child(3) .sub-menu li .sub-menu {
      left: auto;
      right: 100%;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-menu .main-menu li .mega-sub-menu {
    width: 690px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-menu .main-menu li .mega-sub-menu .mega-sub-item .mega-title {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-menu-2 .main-menu li a {
    font-size: 14px;
    padding: 0 8px;
  }
}

.navbar-menu-2 .main-menu li {
  .sub-menu li {
    a.active, &:hover > a {
      color: #084a83;
    }
  }

  .mega-sub-menu .mega-sub-item {
    .mega-title:hover {
      color: #084a83;
    }

    .mega-item li {
      a.active, &:hover a {
        color: #084a83;
      }
    }
  }
}

.navbar-inner .navbar-category .menu-items {
  display: none;
  box-shadow: 2px 4px 8px rgba(34, 34, 34, 0.25);
}

.navbar-2 {
  background-color: #d7dce0;
  margin-top: 0;

  &.sticky {
    background-color: #084a83;
  }

  .navbar-category {
    .category-title {
      background-color: #f0d43a;
      color: #333;
    }

    .menu-items > li {
      &:hover > a {
        color: #084a83;
      }

      .navbar-mega-sub-menu .mega-sub-item {
        .title:hover, .mega-item li a:hover {
          color: #084a83;
        }
      }
    }
  }

  .header-search .search-btn button {
    background-color: #cf0016;
    color: #fff;

    &:hover {
      background-color: #333;
    }
  }
}

.navbar-3 {
  background-color: #0e86c3;
  margin-top: 0;

  &.sticky {
    background-color: #0e86c3;
  }

  .navbar-category .category-title {
    background-color: #0677b1;
  }
}

.navbar-4 {
  background-color: #42b099;
  margin-top: 0;

  &.sticky {
    background-color: #42b099;
  }

  .navbar-category {
    .category-title {
      background-color: #ff6a61;
    }

    .menu-items {
      border: 1px solid #e6e6e6;
      border-top: 0;

      > li {
        &:hover > a {
          color: #ff6a61;
        }

        .navbar-mega-sub-menu .mega-sub-item {
          .title:hover, .mega-item li a:hover {
            color: #ff6a61;
          }
        }
      }
    }
  }

  .navbar-menu .main-menu li {
    a.active, &:hover > a {
      color: #ff6a61;
    }

    .sub-menu li {
      a.active, &:hover > a {
        color: #ff6a61;
      }
    }

    .mega-sub-menu .mega-sub-item {
      .mega-title:hover {
        color: #ff6a61;
      }

      .mega-item li {
        a.active, &:hover a {
          color: #ff6a61;
        }
      }
    }
  }
}

.navbar-mobile {
  margin-top: -22px;

  .navbar-mobile-menu {
    position: fixed;
    top: 0;
    left: -380px;
    width: 350px;
    height: 100%;
    z-index: 999;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 60px;
    padding-bottom: 40px;
    transition: all 0.3s linear;

    &.open {
      left: 0;
    }

    .close-navbar-mobile {
      font-size: 24px;
      color: #333;
      position: absolute;
      top: 10px;
      right: 25px;
      z-index: 9;
    }
  }

  .main-menu li {
    position: relative !important;

    a {
      font-size: 14px;
      color: #333;
      padding: 8px 20px;
      border-top: 1px solid #ededed;
      display: block;

      img {
        margin-right: 10px;
      }

      > i {
        display: none;
      }
    }

    .mobile-menu-expand {
      position: absolute;
      right: 25px;
      top: 13px;
      width: 15px;
      height: 15px;
      cursor: pointer;

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background-color: #333;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        position: absolute;
        content: '';
        width: 2px;
        height: 100%;
        background-color: #333;
        left: 50%;
        bottom: 0;
        transition: all .3s linear;
        transform: translateX(-50%);
      }
    }

    &.active-expand > .mobile-menu-expand::after {
      height: 0;
    }

    li {
      a {
        padding-left: 11%;
      }

      li {
        a {
          padding-left: 16%;
        }

        li {
          a {
            padding-left: 20%;
          }

          li a {
            padding-left: 24%;
          }
        }
      }
    }

    &:hover > a {
      color: $primColor;
    }

    .mega-sub-menu, .navbar-mega-sub-menu, .sub-menu {
      display: none;
    }
  }

  .navbar-mobile-category {
    position: relative;

    .category-title {
      font-size: 13px;
      font-weight: 700;
      font-family: Montserrat, sans-serif;
      color: #fff;
      background-color: $primColorNigger;
      line-height: 44px;
      padding: 0 20px;
      display: block;
      text-transform: uppercase;
      white-space: nowrap;
      display: flex;
      align-items: center;

      i {
        font-size: 20px;
        margin-right: 15px;
      }
    }

    .menu-items {
      background-color: #fff;
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 99;

      li {
        position: relative !important;

        a {
          font-size: 14px;
          color: #333;
          padding: 8px 20px;
          border-top: 1px solid #ededed;
          display: block;

          img {
            margin-right: 10px;
          }

          > i {
            display: none;
          }
        }

        .mobile-menu-expand {
          position: absolute;
          right: 20px;
          top: 13px;
          width: 15px;
          height: 15px;
          cursor: pointer;

          &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            background-color: #333;
            top: 50%;
            transform: translateY(-50%);
          }

          &::after {
            position: absolute;
            content: '';
            width: 1px;
            height: 100%;
            background-color: #333;
            left: 50%;
            bottom: 0;
            transition: all .3s linear;
            transform: translateX(-50%);
          }
        }

        &.active-expand > .mobile-menu-expand::after {
          height: 0;
        }

        li {
          a {
            padding-left: 7%;
          }

          li {
            a {
              padding-left: 10%;
            }

            li {
              a {
                padding-left: 13%;
              }

              li a {
                padding-left: 16%;
              }
            }
          }
        }

        &:hover > a {
          color: $primColor;
        }

        .mega-sub-menu, .navbar-mega-sub-menu, .sub-menu {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .navbar-mobile .navbar-mobile-menu {
    width: 280px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .navbar-mobile .navbar-mobile-menu {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-mobile .navbar-mobile-category .category-title {
    font-size: 14px;
  }
}

.slider-banner {
  padding-top: 30px;
  padding-bottom: 30px;
}

.slider-active .slick-dots {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  li {
    display: inline-block;
    margin: 0 5px;

    button {
      width: 14px;
      height: 14px;
      border: 0;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      transition: all .3s linear;
      font-size: 0;
    }

    &.slick-active button {
      background-color: #cf0016;
    }
  }
}

.single-slider {
  height: 545px;  
  border-radius: 4px;

  .slider-banner-content {
    padding: 70px;

    .sub-title {
      font-size: 24px;
      color: #fff;
      font-weight: 400;
      font-style: italic;
      letter-spacing: .02em;
      text-transform: capitalize;
    }

    .main-title {
      margin-top: 20px;
      font-size: 30px;
      color: #fff;
      font-weight: 700;
      letter-spacing: .02em;
      text-transform: uppercase;
    }

    .title-1 {
      font-size: 24px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
      margin-top: 26px;
    }

    .title-2 {
      font-size: 30px;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: .05em;
      margin-top: 20px;
    }

    .main-btn {
      position: relative;
      color: #fff;
      padding: 0 26px;
      font-size: 14px;
      font-weight: 700;
      height: 40px;
      line-height: 40px;
      margin-top: 35px;
      text-transform: uppercase;
      background-color: #cf0016;

      &:hover {
        background-color: #333;
      }
    }
  }
}

@media (max-width: 767px) {
  .single-slider { 
    //height: 445px;
    height: 240px; 
    background-size: contain !important; 
  }
}

@media only screen and (min-width: 768px) and (max-width: 1599px) {
  .single-slider {
    height: 445px;
    background-size: contain !important;
  } 
}

@media (max-width: 767px) {
  .single-slider .slider-banner-content {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .single-slider .slider-banner-content .sub-title {
    font-size: 14px;
    margin-top: 5px;
  }
}

@media (max-width: 767px) {
  .single-slider .slider-banner-content .main-title {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .single-slider .slider-banner-content .title-1 {
    font-size: 14px;
    margin-top: 5px; 
  }
  .single-slider .slider-banner-content .main-btn{
    margin-top: 15px; 
  }
}

@media (max-width: 767px) {
  .single-slider .slider-banner-content .title-2 {
    font-size: 14px;
    margin-top: 5px;
  } 
}

.slider-block-wrapper .slider-block {
  margin-top: 30px;

  .icon {
    font-size: 32px;
    color: $primColor;
  }

  .text {
    padding-left: 15px;

    .title {
      color: #333;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }

    p {
      font-size: 13px;
      line-height: 20px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-block-wrapper .slider-block .icon {
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-block-wrapper .slider-block .text .title {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-block-wrapper .slider-block .text p {
    font-size: 12px;
  }
}

.slider-2 .single-slider .slider-banner-content {
  padding: 50px;

  .main-btn {
    background-color: #f0d43a;
    color: #333;

    &:hover {
      background-color: #cf0016;
      color: #fff;
    }
  }
}

@media (max-width: 767px) {
  .slider-2 .single-slider .slider-banner-content {
    padding: 30px;
  }
}

.slider-banner-2 {
  padding-top: 0;

  .single-banner a img {
    width: 100%;
    height: 257px;
    object-fit: cover;
    object-position: center;
  }
}

.slider-block-2 {
  background-color: #fff;
  box-shadow: 0 0 5px 0.0125px rgba(0, 0, 0, 0.07);
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;

  .slider-block .icon {
    color: #084a83;
  }
}

.slider-3 {
  background-color: #fff;
  padding-bottom: 0;

  .slider-active .slick-dots li.slick-active button {
    background-color: #31aae8;
  }
}

.slider-banner-content-3-1 {
  padding: 70px;

  .title {
    font-size: 48px;
    line-height: 48px;
    color: #fff;
    font-weight: 700;
    font-style: italic;
    letter-spacing: .05em;
    text-transform: uppercase;
    padding-bottom: 5px;
  }

  .sub-title {
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    letter-spacing: .05em;
    margin-top: 25px;
  }

  .main-btn {
    position: relative;
    color: #fff;
    padding: 0 26px;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    margin-top: 35px;
    text-transform: uppercase;
    background-color: #31aae8;

    &:hover {
      background-color: #333;
    }
  }
}

@media (max-width: 767px) {
  .slider-banner-content-3-1 {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .slider-banner-content-3-1 .title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .slider-banner-content-3-1 .sub-title {
    font-size: 18px;
  }
}

.slider-banner-content-3-2 {
  padding: 70px;

  .title {
    font-size: 48px;
    line-height: 48px;
    color: #fff;
    font-weight: 400;
    letter-spacing: .05em;
    text-transform: uppercase;
    padding-bottom: 5px;
  }

  .sub-title {
    font-size: 30px;
    color: #fff;
    font-weight: 400;
    font-family: Arimo;
    letter-spacing: .05em;
    margin-top: 15px;
  }

  .main-btn {
    position: relative;
    color: #fff;
    padding: 0 26px;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    margin-top: 35px;
    text-transform: uppercase;
    background-color: #31aae8;

    &:hover {
      background-color: #333;
    }
  }
}

@media (max-width: 767px) {
  .slider-banner-content-3-2 {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .slider-banner-content-3-2 .title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .slider-banner-content-3-2 .sub-title {
    font-size: 18px;
  }
}

.slider-block-wrapper-3 {
  .slider-block {
    margin-top: 30px;
    border: 2px solid transparent;
    padding: 25px 0;
    border-radius: 5px;

    .icon {
      font-size: 46px;
      line-height: 50px;
    }

    .text {
      padding-left: 15px;
      text-align: left;

      .title {
        color: #333;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
      }

      p {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  .block-col {
    &:nth-child(1) .slider-block {
      border-color: #f5a72c;

      .icon {
        color: #f5a72c;
      }
    }

    &:nth-child(2) .slider-block {
      border-color: #31aae8;

      .icon {
        color: #31aae8;
      }
    }

    &:nth-child(3) .slider-block {
      border-color: #4dc756;

      .icon {
        color: #4dc756;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-block-wrapper-3 .slider-block .icon {
    font-size: 36px;
    line-height: 45px;
  }
}

@media (max-width: 767px) {
  .slider-block-wrapper-3 .slider-block .icon {
    font-size: 36px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-block-wrapper-3 .slider-block .text .title {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .slider-block-wrapper-3 .slider-block .text .title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .slider-block-wrapper-3 .slider-block .text p {
    font-size: 12px;
  }
}

.slider-banner-4 {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;

  .slider-active .slick-dots li.slick-active button {
    background-color: #42b099;
  }

  .single-banner a img {
    width: 100%;
    height: 257px;
    object-fit: cover;
    object-position: center;
  }
}

@media (max-width: 767px) {
  .slider-banner-4 .single-banner a img {
    height: auto;
  }
}

.slider-banner-content-4-1 {
  padding: 75px 50px;

  .main-title {
    font-size: 28px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }

  .sub-title {
    font-size: 18px;
    color: #fff;
    margin-top: 16px;
    text-transform: uppercase;
  }

  .main-btn {
    position: relative;
    color: #fff;
    padding: 0 26px;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    margin-top: 35px;
    text-transform: uppercase;
    background-color: #42b099;

    &:hover {
      background-color: #ff6a61;
    }
  }
}

@media (max-width: 767px) {
  .slider-banner-content-4-1 {
    padding: 40px 25px;
  }
}

@media (max-width: 767px) {
  .slider-banner-content-4-1 .main-title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .slider-banner-content-4-1 .sub-title {
    font-size: 14px;
  }
}

.slider-banner-content-4-2 {
  padding: 75px 50px;

  .sub-title {
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    font-style: italic;
  }

  .main-title {
    font-size: 36px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
  }

  .main-btn {
    position: relative;
    color: #fff;
    padding: 0 26px;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    margin-top: 35px;
    text-transform: uppercase;
    background-color: #42b099;

    &:hover {
      background-color: #ff6a61;
    }
  }
}

@media (max-width: 767px) {
  .slider-banner-content-4-2 {
    padding: 40px 25px;
  }
}

@media (max-width: 767px) {
  .slider-banner-content-4-2 .sub-title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .slider-banner-content-4-2 .main-title {
    font-size: 20px;
  }
}

.single-banner a {
  display: block;
  position: relative;

  img {
    width: 100%;
    border-radius: 4px;
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: scaleY(0);
    transition: all .3s linear;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }

  &:hover::before {
    opacity: .5;
    visibility: visible;
    transform: scaleY(1);
  }
}

.pos-title {
  padding: 13px 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .title {
    position: relative;
    display: inline-block;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: .05em;
    color: #fff;
    font-size: 18px;
    font-weight: 700;

    i {
      margin-right: 10px;
    }
  }
}

@media (max-width: 767px) {
  .pos-title {
    padding: 10px 15px;
  }
}

@media (max-width: 767px) {
  .pos-title .title {
    font-size: 16px;
  }
}

.special-products {
  .item-product {
    padding: 5px;
    padding-bottom: 62px;
    border: 1px solid #e6e6e6;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .product-image {
      position: relative;

      a {
        display: block;

        img {
          width: 100%;
        }
      }

      .quick-views {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .3s linear;
        opacity: 0;
        visibility: hidden;

        .views {
          width: 38px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          border-radius: 3px;
          background-color: $primColor;
          color: #fff;
          font-size: 18px;
          transition: all .3s linear;
          outline: none;
          border: none;

          &:hover {
            background-color: #cf0016;
          }
        }
      }

      .price-box {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background: #cf0016;
        padding: 13px 0;
        height: 60px;
        width: 60px;
        border-radius: 100%;
        right: 0;
        bottom: 0;
        text-align: center;

        .text {
          display: block;
          text-transform: uppercase;
        }
      }
    }

    .product-contents {
      .manufacturer {
        font-size: 13px;
        color: #999;
        padding-top: 20px;
      }

      .product-name a {
        color: #656565;
        font-size: 18px;
        font-weight: 700;
        transition: all 0.3s linear;

        &:hover {
          color: $primColor;
        }
      }

      .product-reviews {
        padding-top: 5px;

        li {
          font-size: 12px;
          color: #ddd;
          display: inline-block;

          &.rating-on {
            color: #f2b309;
          }
        }
      }

      .price-box {
        margin-top: 20px;

        .regular-price {
          font-size: 18px;
          font-weight: 400;
          color: #555;
          display: block;
          text-decoration: line-through;
        }

        .price-sale {
          font-size: 24px;
          display: block;
          color: $redColor;
          font-weight: 700;
          margin-top: 13px;
          line-height: 20px;
        }
      }
    }

    .product-btn {
      margin-top: 30px;

      .main-btn {
        height: 44px;
        line-height: 42px;
        border: 1px solid #e6e6e6;
        font-size: 14px;
        padding: 0 23px;
        background-color: transparent;
        color: #333;
      }
    }

    .countdown {
      padding-top: 30px;

      .countdown-title {
        position: relative;
        font-size: 15px;
        color: #333;
        padding-top: 30px;

        &::before {
          content: "";
          background: #ededed;
          height: 1px;
          width: 230px;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          margin: 0 auto;
        }
      }

      .countdown-items {
        margin-left: -5px;
        margin-right: -5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .countdown-col {
          padding-left: 5px;
          padding-right: 5px;
        }

        .single-countdown-item {
          background-color: #f2f4f6;
          width: 60px;
          padding: 15px 0;
          border-radius: 4px;
          text-align: center;
          margin-top: 30px;

          .count {
            display: block;
            font-size: 20px;
            font-weight: 500;
            color: #333;
            line-height: 22px;
          }

          .value {
            font-size: 13px;
            font-weight: 400;
            color: #333;
            line-height: 16px;
          }

          &.item-bg {
            background-color: #cf0016;

            .count, .value {
              color: #fff;
            }
          }
        }
      }
    }

    &:hover {
      .product-image .quick-views {
        opacity: 1;
        visibility: visible;
      }

      .product-btn .main-btn {
        border-color: $primColor;
        background-color: $primColor;
        color: #fff;
      }
    }

    &.item-product-2 {
      .product-image .quick-views .views {
        background-color: #084a83;

        &:hover {
          background-color: #cf0016;
        }
      }

      .product-contents .product-name a:hover {
        color: #084a83;
      }

      &:hover .product-btn .main-btn {
        border-color: #084a83;
        background-color: #084a83;
      }
    }
  }

  .slick-arrow {
    width: 38px;
    height: 38px;
    line-height: 36px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    text-align: center;
    font-size: 16px;
    color: #333;
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
    transition: all .3s linear;
    background-color: #fff;
    z-index: 5;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;

    &.next {
      left: auto;
      right: -18px;
    }

    &:hover {
      background-color: $primColor;
      border-color: $primColor;
      color: #fff;
    }
  }

  &:hover .slick-arrow {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .special-products .item-product .countdown .countdown-items .single-countdown-item {
    width: 55px;
  }
}

@media (max-width: 767px) {
  .special-products .item-product .countdown .countdown-items .single-countdown-item .count {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .special-products .slick-arrow {
    left: -12px;
  }
}

@media (max-width: 767px) {
  .special-products .slick-arrow.next {
    right: -12px;
  }
}

.product-post-title {
  padding: 13px 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: inline-block;

  .title {
    position: relative;
    display: inline-block;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: .05em;
    color: #fff;
    font-size: 18px;
    font-weight: 700;

    i {
      margin-right: 10px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-post-title {
    padding: 12px 15px;
  }
}

@media (max-width: 767px) {
  .product-post-title {
    padding: 10px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-post-title .title {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .product-post-title .title {
    font-size: 16px;
  }
}

.title-bg-1 {
  background-color: #cf0016;
}

.title-bg-2 {
  background-color: #4ea522;
}

.title-bg-3 {
  background-color: #ff9b82;
}

.title-bg-4 {
  background-color: #f0d43a;
}

.title-bg-5 {
  background-color: #084a83;
}

.title-bg-6 {
  background-color: $primColor;
}

.title-bg-7 {
  background-color: #f5a72c;
}

.title-bg-8 {
  background-color: #f0d43a;
}

.title-bg-9 {
  background-color: #31aae8;
}

.title-bg-10 {
  background-color: #ff6a61;
}

.title-bg-11 {
  background-color: #42b099;
}

.product-col {
  padding: 1px;
}

.product-best-sale {
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  .product-item + .product-item, .product-item-3 + .product-item-3 {
    margin-top: 15px;
  }
}

.product-item {
  border: 1px solid transparent;
  padding: 5px 5px 10px 5px;
  border-radius: 4px;
  transition: all .3s linear;
  width: 99.7%;

  .product-image {
    position: relative;

    a {
      display: block;

      img {
        width: 100%;
      }
    }

    .quick-views {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s linear;
      opacity: 0;
      visibility: hidden;

      .views {
        width: 38px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border-radius: 3px;
        background-color: $primColor;
        color: #fff;
        font-size: 18px;
        transition: all .3s linear;
        outline: none;
        border: none;

        &:hover {
          background-color: #cf0016;
        }
      }
    }

    .price-box {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      background: #cf0016;
      padding: 13px 0;
      height: 60px;
      width: 60px;
      border-radius: 100%;
      right: 0;
      bottom: 0;
      text-align: center;

      .text {
        display: block;
        text-transform: uppercase;
      }
    }

    .price-box-2 {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      background: #cf0016;
      padding: 13px 0;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      right: 0;
      bottom: 0;
      text-align: center;

      .text {
        display: block;
        text-transform: uppercase;
      }
    }
  }

  .product-content {
    .product-name a {
      color: #656565;
      font-size: 16px;
      font-weight: 500;
      transition: all .3s linear;
      margin-top: 15px;

      &:hover {
        color: $primColor;
      }
    }

    .product-reviews {
      padding-top: 5px;

      li {
        font-size: 10px;
        color: #ddd;
        display: inline-block;

        &.rating-on {
          color: #f2b309;
        }
      }
    }

    .price-box {
      .price-sale {
        font-size: 18px;
        display: inline-block;
        color: $redColor;
        font-weight: 700;
        margin-top: 13px;
        line-height: 20px;
      }

      .regular-price {
        font-size: 15px;
        font-weight: 400;
        color: #555;
        display: inline-block;
        text-decoration: line-through;
        margin-left: 5px;
      }
    }
  }

  .product-btn {
    margin-top: 20px;

    .add-to-links li {
      display: inline-block;
    }

    .main-btn {
      height: 38px;
      line-height: 36px;
      border: 1px solid #e6e6e6;
      font-size: 14px;
      padding: 0 15px;
      background-color: transparent;
      color: #333;
      margin: 0 2px;
      background-color: #fff;
      position: relative;
      z-index: 5;
    }

    .add-to-compare, .add-to-wishlist {
      width: 38px;
      height: 38px;
      line-height: 36px;
      text-align: center;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      color: #333;
      transition: all .3s linear;
      background-color: #fff;
      opacity: 0;
      visibility: hidden;
    }

    .add-to-compare:hover {
      background-color: #cf0016;
      border-color: #cf0016;
      color: #fff;
    }

    .add-to-wishlist {
      &:hover {
        background-color: #cf0016;
        border-color: #cf0016;
        color: #fff;
      }

      transform: translateX(100%);
    }

    .add-to-compare {
      transform: translateX(-100%);
    }
  }

  &:hover {
    border-color: $primColor;

    .product-image .quick-views {
      opacity: 1;
      visibility: visible;
    }

    .product-btn {
      .main-btn {
        border-color: $primColor;
        background-color: $primColor;
        color: #fff;
      }

      .add-to-compare, .add-to-wishlist {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.product-item-2:hover {
    border-color: #084a83;

    .product-image .quick-views .views {
      background-color: #084a83;

      &:hover {
        background-color: #cf0016;
      }
    }

    .product-content .product-name a:hover {
      color: #084a83;
    }

    .product-btn .main-btn {
      border-color: #084a83;
      background-color: #084a83;
    }
  }

  &.product-item-3 {
    .product-image .price-box-2 {
      background-color: #ff6a61;
    }

    &:hover {
      border-color: #42b099;

      .product-image .quick-views .views {
        background-color: #42b099;

        &:hover {
          background-color: #cf0015;
        }
      }

      .product-content .product-name a:hover {
        color: #42b099;
      }

      .product-btn .main-btn {
        border-color: #42b099;
        background-color: #42b099;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-item .product-btn .main-btn {
    height: 35px;
    line-height: 33px;
    padding: 0 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-item .product-btn {
    .add-to-compare, .add-to-wishlist {
      width: 35px;
      height: 35px;
      line-height: 33px;
    }
  }
}

.product-active .slick-arrow {
  width: 38px;
  height: 38px;
  line-height: 36px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  text-align: center;
  font-size: 16px;
  color: #333;
  position: absolute;
  top: -54px;
  right: 0;
  transition: all .3s linear;
  background-color: #fff;
  z-index: 5;
  cursor: pointer;

  &.prev {
    right: 43px;
  }

  &:hover {
    background-color: $primColor;
    border-color: $primColor;
    color: #fff;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-active .slick-arrow {
    top: -45px;
    width: 34px;
    height: 34px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .product-tab-menu .nav li {
    padding-top: 10px;
  }
}

.product-tab-menu {
  .nav li a {
    font-size: 15px;
    font-weight: 400;
    color: #333;
    padding: 6px 16px;
    border: 1px solid transparent;
    border-radius: 4px;
    line-height: 18px;
    transition: all 0.3s linear;

    &.active {
      border-color: #000;
    }
  }

  &.tab-menu-1 .nav li {
    margin-right: 5px;

    a {
      &.active, &:hover {
        border-color: #4ea522;
        color: #4ea522;
      }
    }
  }

  &.tab-menu-2 .nav li {
    margin-right: 5px;

    a {
      &.active, &:hover {
        border-color: #ff9b82;
        color: #ff9b82;
      }
    }
  }

  &.tab-menu-3 .nav li {
    margin-right: 5px;

    a {
      &.active, &:hover {
        border-color: #f0d43a;
        color: #f0d43a;
      }
    }
  }

  &.tab-menu-4 .nav li {
    margin-right: 5px;

    a {
      &.active, &:hover {
        border-color: #084a83;
        color: #084a83;
      }
    }
  }

  &.tab-menu-5 .nav li {
    margin-right: 5px;

    a {
      &.active, &:hover {
        border-color: #31aae8;
        color: #31aae8;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-menu .nav li a {
    padding: 5px 8px;
    font-size: 13px;
  }
}

.post-content {
  border: 1px solid #e6e6e6;
  padding: 20px;
  padding-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  .banner {
    overflow: hidden;
    margin-top: 20px;
    padding-bottom: 10px;

    a {
      display: block;
      overflow: hidden;

      img {
        width: 100%;
        transition: all 0.3s linear;
      }
    }

    &:hover a img {
      transform: scale(1.05);
    }
  }

  .post-content-product {
    margin-top: 20px;
  }

  .tab-content .tab-pane.active .product-item {
    animation: slickZoomIn 1s;
  }
}

@media (max-width: 767px) {
  .post-content {
    margin-top: 10px;
  }
}

.product-active-2 {
  .slick-active {
    animation: slickZoomIn 1s;
  }

  .slick-arrow {
    width: 38px;
    height: 38px;
    line-height: 36px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    text-align: center;
    font-size: 16px;
    color: #333;
    position: absolute;
    top: -330px;
    right: -20px;
    transition: all .3s linear;
    background-color: #fff;
    z-index: 5;
    cursor: pointer;

    &.prev {
      right: 23px;
    }

    &:hover {
      background-color: $primColor;
      border-color: $primColor;
      color: #fff;
    }
  }

  &.without-banners .slick-arrow {
    top: -64px;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .product-active-2 .slick-arrow {
    top: -300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-active-2 .slick-arrow {
    top: -258px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-active-2 .slick-arrow {
    top: -205px;
    width: 34px;
    height: 34px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .product-active-2 .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
    width: 34px;
    height: 34px;
    line-height: 32px;
    right: -40px;
  }
}

@media (max-width: 767px) {
  .product-active-2 .slick-arrow.prev {
    right: auto;
    left: -40px;
  }
}

@media (max-width: 768px) {
  .product-active-2.without-banners .slick-arrow {
    top: 50%;
  }
}

.content-product-2 .slick-arrow {
  width: 38px;
  height: 38px;
  line-height: 36px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  text-align: center;
  font-size: 16px;
  color: #333;
  position: absolute;
  top: -80px;
  right: -20px;
  transition: all .3s linear;
  background-color: #fff;
  z-index: 5;
  cursor: pointer;

  &.prev {
    right: 23px;
  }

  &:hover {
    background-color: #084a83;
    border-color: #084a83;
    color: #fff;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .content-product-2 .slick-arrow {
    top: -73px;
    width: 34px;
    height: 34px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .content-product-2 .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
    width: 34px;
    height: 34px;
    line-height: 32px;
    right: -40px;
  }
}

@keyframes slickZoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.product-arrivals {
  border: 1px solid #e6e6e6;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 20px 20px 10px;

  .product-arrivals-items .product-arrivals-item {
    .product-image {
      position: relative;

      a {
        display: block;

        img {
          width: 100%;
        }
      }

      .quick-views {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .3s linear;
        opacity: 0;
        visibility: hidden;

        .views {
          width: 38px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          border-radius: 3px;
          background-color: $primColor;
          color: #fff;
          font-size: 18px;
          transition: all .3s linear;
          outline: none;
          border: none;

          &:hover {
            background-color: #cf0016;
          }
        }
      }

      .price-box {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background: #cf0016;
        padding: 13px 0;
        height: 60px;
        width: 60px;
        border-radius: 100%;
        right: 0;
        bottom: 0;
        text-align: center;

        .text {
          display: block;
          text-transform: uppercase;
        }
      }
    }

    .product-content {
      .product-name a {
        color: #656565;
        font-size: 16px;
        font-weight: 500;
        transition: all .3s linear;
        margin-top: 15px;

        &:hover {
          color: $primColor;
        }
      }

      .product-reviews li {
        font-size: 10px;
        color: #ddd;
        display: inline-block;

        &.rating-on {
          color: #f2b309;
        }
      }

      .price-box {
        .price-sale {
          font-size: 18px;
          display: inline-block;
          color: $redColor;
          font-weight: 700;
          margin-top: 13px;
          line-height: 20px;
        }

        .regular-price {
          font-size: 15px;
          font-weight: 400;
          color: #555;
          display: inline-block;
          text-decoration: line-through;
          margin-left: 5px;
        }
      }
    }
  }

  .product-thumb {
    margin-top: 15px;

    .single-thumb-item {
      display: flex;
      align-items: center;
      border-top: 1px solid #e6e6e6;
      padding: 10px 0;
      cursor: pointer;

      .Wednesday {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background: $primColor;
        display: inline-block;
        line-height: 30px;
        text-align: center;
        color: #ededed;
        font-size: 14px;
        font-weight: 700;
        transition: all 0.3s linear;
      }

      .item-inner {
        padding-left: 20px;
        flex: 1;

        p {
          color: #555;
          font-size: 15px;
          transition: all 0.3s linear;
        }
      }

      &:hover, &.slick-current {
        .Wednesday {
          background: #cf0016;
        }

        .item-inner p {
          color: $primColor;
        }
      }
    }
  }

  &.product-arrivals-2 {
    .product-arrivals-items .product-arrivals-item .product-content .product-name a {
      color: #656565;

      &:hover {
        color: $primColor;
      }
    }

    .product-thumb .single-thumb-item {
      border-top: 1px solid #e6e6e6;

      .Wednesday {
        background: #ff6a61;
        color: #ededed;
      }

      .item-inner p {
        color: #555;
        font-size: 15px;
        transition: all 0.3s linear;
      }

      &:hover, &.slick-current {
        .Wednesday {
          background: #42b099;
        }

        .item-inner p {
          color: #42b099;
        }
      }
    }
  }
}

.special-products-2 {
  .item-product {
    padding: 5px;
    padding-bottom: 30px;
    border: 1px solid #e6e6e6;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .product-image {
      position: relative;

      a {
        display: block;

        img {
          width: 100%;
        }
      }

      .product-meta {
        overflow: hidden;

        li {
          display: inline-block;
          transform: translateY(40px);
          opacity: 0;
          transition: all .3s linear;
          margin: 0 3px;

          &:nth-child(1) {
            transition-delay: 0.2s;
          }

          &:nth-child(2) {
            transition-delay: 0.3s;
          }

          &:nth-child(3) {
            transition-delay: 0.4s;
          }

          a {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            color: #333;
            transition: all 0.3s linear;

            &:hover {
              background-color: $primColor;
              color: #fff;
            }
          }
        }
      }

      .price-box {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background: #cf0016;
        padding: 13px 0;
        height: 60px;
        width: 60px;
        border-radius: 100%;
        right: 0;
        bottom: 0;
        text-align: center;

        .text {
          display: block;
          text-transform: uppercase;
        }
      }
    }

    .product-contents {
      .manufacturer {
        font-size: 13px;
        color: #999;
        padding-top: 20px;
      }

      .product-name a {
        color: #656565;
        font-size: 18px;
        font-weight: 700;
        transition: all 0.3s linear;

        &:hover {
          color: $primColor;
        }
      }

      .product-reviews {
        padding-top: 5px;

        li {
          font-size: 12px;
          color: #ddd;
          display: inline-block;

          &.rating-on {
            color: #f2b309;
          }
        }
      }

      .price-box {
        margin-top: 20px;

        .regular-price {
          font-size: 18px;
          font-weight: 400;
          color: #555;
          display: block;
          text-decoration: line-through;
        }

        .price-sale {
          font-size: 24px;
          display: block;
          color: $redColor;
          font-weight: 700;
          margin-top: 13px;
          line-height: 20px;
        }
      }
    }

    .product-btn {
      margin-top: 30px;

      .main-btn {
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        padding: 0 23px;
        background-color: #333;
        color: #fff;

        &:hover {
          background-color: $primColor;
          color: #fff;
        }
      }
    }

    .countdown {
      padding-top: 30px;

      .countdown-title {
        position: relative;
        font-size: 15px;
        color: #333;
        padding-top: 30px;

        &::before {
          content: "";
          background: #ededed;
          height: 1px;
          width: 230px;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          margin: 0 auto;
        }
      }

      .countdown-items {
        margin-left: -5px;
        margin-right: -5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .countdown-col {
          padding-left: 5px;
          padding-right: 5px;
        }

        .single-countdown-item {
          background-color: #f2f4f6;
          width: 50px;
          padding: 15px 0;
          border-radius: 4px;
          text-align: center;
          margin-top: 30px;

          .count {
            display: block;
            font-size: 18px;
            font-weight: 500;
            color: #333;
            line-height: 22px;
          }

          .value {
            font-size: 13px;
            font-weight: 400;
            color: #333;
            line-height: 16px;
          }

          &.item-bg {
            background-color: #cf0016;

            .count, .value {
              color: #fff;
            }
          }
        }
      }
    }

    &:hover .product-image .product-meta li {
      transform: translateY(0);
      opacity: 1;
    }

    &.item-product-2 {
      .product-image .quick-views .views {
        background-color: #084a83;

        &:hover {
          background-color: #cf0016;
        }
      }

      .product-contents .product-name a:hover {
        color: #084a83;
      }

      &:hover .product-btn .main-btn {
        border-color: #084a83;
        background-color: #084a83;
      }
    }
  }

  .slick-arrow {
    width: 38px;
    height: 38px;
    line-height: 36px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    text-align: center;
    font-size: 16px;
    color: #333;
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
    transition: all .3s linear;
    background-color: #fff;
    z-index: 5;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;

    &.next {
      left: auto;
      right: -18px;
    }

    &:hover {
      background-color: $primColor;
      border-color: $primColor;
      color: #fff;
    }
  }

  &:hover .slick-arrow {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .special-products-2 .item-product .countdown .countdown-items .single-countdown-item {
    width: 55px;
  }
}

@media (max-width: 767px) {
  .special-products-2 .item-product .countdown .countdown-items .single-countdown-item .count {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .special-products-2 .slick-arrow {
    left: -12px;
  }
}

@media (max-width: 767px) {
  .special-products-2 .slick-arrow.next {
    right: -12px;
  }
}

.product-item-3 {
  border: 1px solid transparent;
  padding: 5px 5px 10px 5px;
  border-radius: 4px;
  transition: all .3s linear;
  width: 99.7%;

  .product-image {
    position: relative;

    a {
      display: block;

      img {
        width: 100%;
      }
    }

    .product-meta {
      overflow: hidden;

      li {
        display: inline-block;
        transform: translateY(40px);
        opacity: 0;
        transition: all .3s linear;
        margin: 0 3px;

        &:nth-child(1) {
          transition-delay: 0.2s;
        }

        &:nth-child(2) {
          transition-delay: 0.3s;
        }

        &:nth-child(3) {
          transition-delay: 0.4s;
        }

        a {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          color: #333;
          transition: all 0.3s linear;

          &:hover {
            background-color: $primColor;
            color: #fff;
          }
        }
      }
    }

    .price-box {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      background: #cf0016;
      padding: 13px 0;
      height: 60px;
      width: 60px;
      border-radius: 100%;
      right: 0;
      bottom: 0;
      text-align: center;

      .text {
        display: block;
        text-transform: uppercase;
      }
    }
  }

  .product-content {
    .product-name a {
      color: #656565;
      font-size: 16px;
      font-weight: 500;
      transition: all .3s linear;
      margin-top: 15px;

      &:hover {
        color: $primColor;
      }
    }

    .product-reviews {
      padding-top: 5px;

      li {
        font-size: 10px;
        color: #ddd;
        display: inline-block;

        &.rating-on {
          color: #f2b309;
        }
      }
    }

    .price-box {
      .price-sale {
        font-size: 18px;
        display: inline-block;
        color: $redColor;
        font-weight: 700;
        margin-top: 13px;
        line-height: 20px;
      }

      .regular-price {
        font-size: 15px;
        font-weight: 400;
        color: #555;
        display: inline-block;
        text-decoration: line-through;
        margin-left: 5px;
      }
    }
  }

  .product-btn {
    margin-top: 20px;

    .main-btn {
      height: 38px;
      line-height: 36px;
      border: 1px solid #e6e6e6;
      font-size: 14px;
      padding: 0 15px;
      background-color: transparent;
      color: #333;
      margin: 0 2px;
      background-color: #fff;
      position: relative;
      z-index: 5;
    }
  }

  &:hover {
    border-color: $primColor;

    .product-image .product-meta li {
      transform: translateY(0);
      opacity: 1;
    }

    .product-btn .main-btn {
      border-color: $primColor;
      background-color: $primColor;
      color: #fff;
    }
  }
}

.product-active-3 .slick-arrow {
  width: 38px;
  height: 38px;
  line-height: 36px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  text-align: center;
  font-size: 16px;
  color: #333;
  position: absolute;
  top: -54px;
  right: 0;
  transition: all .3s linear;
  background-color: #fff;
  z-index: 5;
  cursor: pointer;

  &.prev {
    right: 43px;
  }

  &:hover {
    background-color: $primColor;
    border-color: $primColor;
    color: #fff;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-active-3 .slick-arrow {
    top: -45px;
    width: 34px;
    height: 34px;
    line-height: 32px;
  }
}

.product-tab-items .product-active-3 .slick-arrow {
  top: -72px;
  right: -20px;

  &.prev {
    right: 25px;
  }
}

.product-tab-menu-2 .nav li {
  margin-top: 10px;

  a {
    display: inline-block;
    line-height: 18px;
    padding: 17px 25px;
    font-weight: 700;
    margin-right: 5px;
    text-transform: uppercase;
    letter-spacing: .02em;
    cursor: pointer;
    color: #fff;
    border-radius: 3px;
    background: #42b099;
    font-size: 18px;
    transition: all 0.3s linear;

    &.active, &:hover {
      background-color: #ff6a61;
    }
  }
}

@media (max-width: 767px) {
  .product-tab-menu-2 .nav li a {
    font-size: 14px;
    padding: 12px 20px;
  }
}

.product-tab-content {
  border: 1px solid #e6e6e6;
  padding: 20px;
  border-radius: 4px;

  .product-item + .product-item {
    margin-top: 15px;
  }

  .product-active-2 {
    .slick-active {
      animation: slickZoomIn 1s;
    }

    .slick-arrow:hover {
      background-color: #42b099;
      border-color: #42b099;
    }
  }
}

.single-product-list {
  padding: 20px 0;

  + .single-product-list {
    border-top: 1px solid #e6e6e6;
  }

  .product-image {
    position: relative;

    a {
      display: block;

      img {
        width: 100%;
      }
    }

    .quick-views {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s linear;
      opacity: 0;
      visibility: hidden;

      .views {
        width: 38px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border-radius: 3px;
        background-color: $primColor;
        color: #fff;
        font-size: 18px;
        transition: all .3s linear;
        outline: none;
        border: none;

        &:hover {
          background-color: #cf0016;
        }
      }
    }

    .price-box {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      background: #cf0016;
      padding: 13px 0;
      height: 60px;
      width: 60px;
      border-radius: 100%;
      right: 0;
      bottom: 0;
      text-align: center;

      .text {
        display: block;
        text-transform: uppercase;
      }
    }

    .price-box-2 {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      background: #cf0016;
      padding: 13px 0;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      right: 0;
      bottom: 0;
      text-align: center;

      .text {
        display: block;
        text-transform: uppercase;
      }
    }
  }

  .product-content {
    .product-name a {
      color: #656565;
      font-size: 18px;
      font-weight: 700;
      transition: all 0.3s linear;

      &:hover {
        color: $primColor;
      }
    }

    .product-reviews {
      padding-top: 5px;

      li {
        font-size: 10px;
        color: #ddd;
        display: inline-block;

        &.rating-on {
          color: #f2b309;
        }
      }
    }

    .price-box {
      .price-sale {
        font-size: 24px;
        display: inline-block;
        color: $redColor;
        font-weight: 700;
        margin-top: 13px;
        line-height: 20px;
      }

      .regular-price {
        font-size: 18px;
        font-weight: 400;
        color: #555;
        display: inline-block;
        text-decoration: line-through;
        margin-left: 5px;
      }
    }

    .description {
      font-size: 15px;
      padding-top: 15px;
    }

    .product-btn {
      margin-top: 30px;

      .add-to-links li {
        display: inline-block;
        margin-right: 2px;
      }

      .main-btn {
        height: 38px;
        line-height: 36px;
        border: 1px solid #e6e6e6;
        font-size: 14px;
        padding: 0 15px;
        background-color: transparent;
        color: #333;
        background-color: #fff;
        position: relative;
        z-index: 5;
      }

      .add-to-compare, .add-to-wishlist {
        width: 38px;
        height: 38px;
        line-height: 36px;
        text-align: center;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        color: #333;
        transition: all .3s linear;
        background-color: #fff;
      }
    }

    .stock {
      padding: 5px 8px 5px 8px;
      font-size: 15px;
      color: #fff;
      border-radius: 5px;
      background-color: #333;
      font-weight: 600;
      margin-top: 30px;
    }
  }

  &:hover {
    .product-image .quick-views {
      opacity: 1;
      visibility: visible;
    }

    .product-btn {
      .main-btn {
        border-color: $primColor;
        background-color: $primColor;
        color: #fff;
      }

      .add-to-compare, .add-to-wishlist {
        background-color: #cf0016;
        border-color: #cf0016;
        color: #fff;
      }
    }
  }
}

@media (max-width: 767px) {
  .single-product-list .product-content .product-name a {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .single-product-list .product-content .price-box .price-sale {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .single-product-list .product-content .price-box .regular-price {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .single-product-list .product-content .stock {
    font-size: 13px;
  }
}

.brand-area {
  padding-top: 40px;
}

.brand-wrapper {
  border: 1px solid #e6e6e6;
  padding: 30px 0;
}

.brand-area a {
  display: block;

  img {
    display: block;
    margin: 0 auto;
  }
}

.product-category-content {
  border: 1px solid transparent;
  padding: 0 30px 30px;
  border-radius: 0 4px 4px 4px;
  transition: border-color .2s ease-in-out;

  .single-category {
    width: 100%;

    .category-image {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 4px;

      .image__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        background-color: rgba($primColorNigger, .35);
        backdrop-filter: blur(15px) saturate(180%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity .2s ease-in-out;
        z-index: 1;

        .overlay__count {
          margin-bottom: 10px;
        }

        .overlay__btn {
            font-size: 10px;
            height: 38px;
            line-height: 38px;
            padding: 0 12px;
            text-transform: uppercase;
            font-family: Montserrat, sans-serif;
            margin-top: 20px;
            background-color: #fff;
            color: #333;
        }
      }
      img {
        width: 100%;
        transition: all 0.3s linear;
      }
    }

    .category-name {
      text-align: center;
      font-size: 16px;
      margin-top: 15px;
      color: #656565;
      transition: color .2s ease-in-out;
    }

    &:hover {
      border-color: $primColor;
      .category-image {
        .image__overlay {
          opacity: 1;

          .overlay__count {}
          .overlay__btn {}
        }

        img {
          transform: scale(1.05);
        }
      }

      .category-name {
        color: $primColor;
      }
    }
  }
}

.newsletter-area {
  background-color: #f2f4f6;
  padding: 22px 0 52px;
}

.newsletter-block {
  display: flex;
  flex-wrap: wrap;

  .newsletter-tilte {
    width: 35%;

    .title {
      font-size: 24px;
      font-weight: 700;
      margin: 0;
      color: #333;
      font-family: Montserrat, sans-serif;
      letter-spacing: .05em;
      text-transform: uppercase;
    }

    p {
      font-size: 16px;
      color: #333;
      line-height: 24px;
      letter-spacing: .05em;
      padding-top: 5px;
    }
  }

  .newsletter-form {
    position: relative;
    width: 65%;

    input {
      height: 50px;
      background: #fff;
      border: 0;
      color: #333;
      padding: 5px 150px 5px 10px;
      display: inline-block;
      letter-spacing: .05em;
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      width: 100%;
      transition: all .3s linear;

      &::placeholder {
        color: #b9b9b9;
      }

      &:focus {
        border-color: $primColor;
      }
    }

    button {
      position: absolute;
      top: 1px;
      right: 1px;
      height: 48px;
      padding: 0 15px;
      background: $primColor;
      border-radius: 0 3px 3px 0;
      border: 0;
      color: #fff;
      display: inline-block;
      vertical-align: top;
      line-height: 50px;
      transition: all 0.3s linear;
    }
  }
}

@media (max-width: 767px) {
  .newsletter-block .newsletter-tilte {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-block .newsletter-tilte .title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-block .newsletter-tilte p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .newsletter-block .newsletter-form {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .newsletter-block .newsletter-form input {
    padding: 5px 10px 5px 10px;
  }
}

@media (max-width: 767px) {
  .newsletter-block .newsletter-form button {
    position: relative;
    right: 0;
    width: 100%;
    margin-top: 10px;
  }
}

.social-block {
  text-align: right;

  .social li {
    display: inline-block;

    + li {
      margin-left: 10px;
    }

    a {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      background: #333;
      font-size: 18px;
      height: 48px;
      width: 48px;
      line-height: 48px;
      border-radius: 100%;
      padding: 0;
      text-align: center;
      z-index: 1;
      transition: all 0.3s linear;

      &:hover {
        background-color: $primColor;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social-block {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .social-block {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .social-block .social li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}

.newsletter-2 .newsletter-form {
  input:focus {
    border-color: #084a83;
  }

  button {
    background: #084a83;
  }
}

.social-block-2 .social li a:hover {
  background-color: #084a83;
}

.newsletter-4 .newsletter-form {
  input:focus {
    border-color: #42b099;
  }

  button {
    background: #42b099;

    &:hover {
      background: #333;
    }
  }
}

.social-block-4 .social li a:hover {
  background-color: #42b099;
}

.footer-widget {
  padding-top: 20px;
  padding-bottom: 45px;

  .footer-title {
    color: #3f3f3f;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget .footer-title {
    font-size: 14px;
  }
}

.single-footer-widget {
  .footer-link {
    margin-top: 25px;

    li {
      line-height: 35px;

      a {
        font-size: 15px;
        color: #555;
        position: relative;
        display: inline-block;
        transition: all 0.3s linear;

        &:hover {
          color: $primColor;
          padding-left: 5px;
        }

        i {
          font-size: 10px;
          margin-right: 5px;
        }
      }
    }
  }

  .footer-contact {
    margin-top: 10px;

    p {
      font-size: 15px;
      color: #555;
      position: relative;
      display: inline-block;

      a {
        color: #555;
        transition: all 0.3s linear;

        &:hover {
          color: $primColor;
        }
      }
    }

    .single-contact {
      padding-top: 15px;
    }
  }
}

.footer-tag-payment {
  border-top: 1px solid #e6e6e6;
  padding-top: 40px;
  margin-top: 40px;

  .footer-tags a {
    display: inline-block;
    margin: 11px 0 0;
    padding: 0 5px;
    border-right: 1px solid #adadad;
    line-height: 13px;
    font-weight: 300;
    color: #666;
    letter-spacing: .02em;
    font-size: 14px;
    transition: all 0.3s linear;

    &:hover {
      color: $primColor;
      padding-left: 5px;
    }
  }

  .footer-payment {
    padding-top: 40px;
  }
}

.footer-copyright {
  padding: 22px 0;
  background: #f2f4f6;

  .copyright-text p {
    margin: 0;
    color: #666;
    font-size: 14px;
    line-height: 25px;

    a {
      color: #666;
      transition: all 0.3s linear;

      &:hover {
        color: $primColor;
      }
    }
  }
}
  
.back-to-top {
  position: fixed;
  bottom: 50px;
  right: 20px;
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  z-index: 99;
  text-align: center;
  display: none;
  box-shadow: 0 0 30px 0 rgba(156, 156, 156, 0.25);
  transition: all .4s linear;
  color: #fff;
  background-color: #333;

  &:hover {
    color: #fff;
    background-color: $primColor;
  }
}

.footer-2 {
  .single-footer-widget {
    .footer-link li a:hover, .footer-contact p a:hover {
      color: #084a83;
    }
  }

  .footer-tag-payment {
    .footer-tags a:hover {
      color: #084a83;
    }

    .footer-payment {
      padding-top: 40px;
    }
  }

  .footer-copyright .copyright-text p a:hover {
    color: #084a83;
  }
}

.footer-4 {
  .single-footer-widget {
    .footer-link li a:hover, .footer-contact p a:hover {
      color: #42b099;
    }
  }

  .footer-tag-payment {
    .footer-tags a:hover {
      color: #42b099;
    }

    .footer-payment {
      padding-top: 40px;
    }
  }

  .footer-copyright .copyright-text p a:hover {
    color: #42b099;
  }
}

.back-to-top-2:hover {
  background-color: #084a83;
  background-color: #42b099;
}

.modal {
  &.fade {
    display: block !important;
    opacity: 0;
    visibility: hidden;

    &.show {
      display: block !important;
      opacity: 1;
      visibility: visible;
    }
  }

  .modal-dialog {
    max-width: 1080px;

    .modal-content {
      border: 0;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

      .modal-header {
        padding: 10px 20px 0;
        margin: 0;
        border-bottom: 0;
      }

      .modal-body {
        padding: 20px;

        .product-quick-view-image {
          .quick-view-image .single-image {
            border: 1px solid #e6e6e6;
            border-radius: 4px;

            img {
              display: inline-block;
              width: 100%;
              border-radius: 4px;
            }
          }

          .quick-view-thumb {
            width: 220px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;

            .thumb-col {
              padding-left: 10px;
              padding-right: 10px;
            }

            .single-thumb {
              border: 1px solid #e6e6e6;
              border-radius: 4px;
              cursor: pointer;
              transition: all 0.3s linear;

              img {
                width: 100%;
                border-radius: 4px;
              }
            }

            .slick-current .single-thumb {
              border-color: $primColor;
            }

            .slick-arrow {
              width: 38px;
              height: 38px;
              line-height: 36px;
              border-radius: 4px;
              border: 1px solid #e6e6e6;
              text-align: center;
              font-size: 16px;
              color: #333;
              position: absolute;
              top: 50%;
              right: -38px;
              transition: all .3s linear;
              transform: translateY(-50%);
              background-color: #fff;
              z-index: 5;
              cursor: pointer;

              &.prev {
                right: auto;
                left: -38px;
              }

              &:hover {
                background-color: $primColor;
                border-color: $primColor;
                color: #fff;
              }
            }
          }
        }

        .product-quick-view-content {
          padding-top: 25px;

          .product-title {
            color: #555;
            font-size: 24px;
            font-weight: 700;
            padding-bottom: 20px;
          }

          .product-condition, .product-reference {
            font-size: 13px;
            margin-top: 6px;
          }

          .product-condition strong, .product-reference strong {
            color: #444;
          }

          .product-price {
            .sale-price {
              color: #333;
              font-size: 30px;
              display: inline-block;
              font-weight: 700;
            }

            .regular-price {
              font-size: 18px;
              font-weight: 400;
              color: #666;
              text-decoration: line-through;
              display: inline-block;
              margin-left: 5px;
            }
          }

          .description {
            border-top: 1px solid #e6e6e6;
            padding-top: 25px;
            margin-top: 25px;
          }

          .product-quantity {
            margin-top: 45px;
            display: flex;
            align-items: center;

            .title {
              font-size: 14px;
              color: #333;
              font-weight: 700;
            }

            .quantity {
              flex: 1;
              padding-left: 10px;

              input {
                width: 60px;
                height: 40px;
                border: 1px solid #e6e6e6;
                padding: 0 10px;
                text-align: center;
              }

              button {
                width: 30px;
                height: 40px;
                background-color: transparent;
                border: 1px solid #e6e6e6;
              }
            }
          }

          .product-btn {
            padding-top: 15px;

            .main-btn {
              height: 40px;
              line-height: 40px;
              padding: 0 25px;
              font-size: 14px;
              background-color: $redColor;

              &:hover {
                color: #fff;
                background-color: #333;
              }
            }
          }

          .usefull-link {
            margin-left: -15px;
            padding-top: 15px;

            li {
              display: inline-block;
              padding-left: 15px;

              a {
                color: #828282;
                font-weight: 400;
                position: relative;
                line-height: 22px;
                display: inline-block;
                font-size: 13px;
                transition: all 0.3s linear;

                i {
                  margin-right: 5px;
                }

                &:hover {
                  color: $primColor;
                }
              }
            }
          }

          .product-size {
            margin-top: 25px;
            display: flex;
            align-items: center;

            .title {
              font-weight: 400;
              font-size: 13px;
              margin-right: 10px;
              line-height: 35px;
              color: #444;
            }

            select {
              width: 228px;
              height: 30px;
              font-size: 14px;
              padding: 0 10px;
              border: 1px solid #e6e6e6;
              color: #666;
            }
          }

          .color-wrapper {
            .title {
              font-weight: 400;
              font-size: 13px;
              margin-right: 10px;
              line-height: 35px;
              color: #444;
            }

            .product-color {
              display: flex;

              li {
                padding: 2px;
                border: 1px solid transparent;
                display: flex;

                + li {
                  margin-left: 5px;
                }

                span {
                  width: 22px;
                  height: 22px;
                  transition: all .3s linear;
                  cursor: pointer;
                }

                &.active {
                  border-color: #333;
                  box-shadow: 2px 2px 4px 0 rgba(51, 51, 51, 0.25);
                }
              }
            }
          }

          .available {
            margin-top: 10px;
            padding-bottom: 25px;

            p {
              font-size: 13px;
              color: #666;

              .available-statut {
                line-height: 18px;
                font-weight: 700;
                color: #fff;
                background-color: #333;
                padding: 3px 8px 4px;
                margin-left: 5px;
              }
            }
          }

          .product-share {
            display: flex;
            align-items: center;
            border-top: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            padding: 25px 0;

            .title {
              font-size: 14px;
              color: #333;
              font-weight: 700;
            }

            .social li {
              display: inline-block;
              margin-left: 25px;

              a {
                font-size: 18px;
                color: #333;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal .modal-dialog {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal .modal-dialog {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .modal .modal-dialog {
    max-width: 90%;
    margin: 30px auto;
  }
}

@media (max-width: 767px) {
  .modal .modal-dialog {
    max-width: 85%;
  }
}

@media (max-width: 767px) {
  .modal .modal-dialog .modal-content .modal-body .product-quick-view-image .quick-view-thumb {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal .modal-dialog .modal-content .modal-body .product-quick-view-image .quick-view-thumb .slick-arrow {
    width: 34px;
    height: 34px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .modal .modal-dialog .modal-content .modal-body .product-quick-view-content .product-title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .modal .modal-dialog .modal-content .modal-body .product-quick-view-content .product-share .social li {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .modal .modal-dialog .modal-content .modal-body .product-quick-view-content .product-share .social li a {
    font-size: 16px;
  }
}

.mini-product-title {
  padding: 15px 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .title {
    text-transform: uppercase;
    color: #fff;
    letter-spacing: .05em;
    font-size: 18px;
    font-weight: 700;

    i {
      margin-right: 10px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mini-product-title .title {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .mini-product-title .title {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mini-product-title .title i {
    margin-right: 5px;
  }
}

@media (max-width: 767px) {
  .mini-product-title .title i {
    margin-right: 5px;
  }
}

.mini-product-wrapper {
  border: 1px solid #e6e6e6;
  padding: 15px;
  padding-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  .single-mini-product {
    display: flex;
    margin-top: 15px;

    .mini-product-thumb img {
      width: 125px;
    }

    .mini-product-content {
      flex: 1;
      padding-left: 20px;

      .product-name a {
        color: #656565;
        font-size: 15px;
        font-weight: 500;
        transition: all .3s linear;
        margin-top: 15px;

        &:hover {
          color: $primColor;
        }
      }

      .product-reviews li {
        font-size: 10px;
        color: #ddd;
        display: inline-block;

        &.rating-on {
          color: #f2b309;
        }
      }

      .price-box {
        .price-sale {
          font-size: 18px;
          display: inline-block;
          color: $redColor;
          font-weight: 700;
          margin-top: 8px;
          line-height: 20px;
        }

        .regular-price {
          font-size: 15px;
          font-weight: 400;
          color: #555;
          display: inline-block;
          text-decoration: line-through;
          margin-left: 5px;
        }
      }
    }

    &.mini-product-2 .mini-product-thumb img {
      width: 144px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mini-product-wrapper .single-mini-product.mini-product-2 .mini-product-thumb img {
    width: 72px;
  }
}

@media (max-width: 767px) {
  .mini-product-wrapper .single-mini-product.mini-product-2 .mini-product-thumb img {
    width: 72px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mini-product-wrapper .single-mini-product.mini-product-2 .mini-product-thumb img {
    width: 144px;
  }
}

.mini-product-active .slick-arrow {
  font-size: 16px;
  color: #fff;
  position: absolute;
  transition: all .3s linear;
  top: -37px;
  z-index: 5;
  cursor: pointer;
  right: 10px;
  width: 18px;
  text-align: right;
  line-height: 1;

  &.prev {
    border-right: 1px solid #e6e6e6;
    right: 28px;
    text-align: left;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mini-product-active .slick-arrow {
    top: -32px;
  }
}

@media (max-width: 767px) {
  .mini-product-active .slick-arrow {
    top: -32px;
  }
}

.featured-product .mini-product-wrapper .single-mini-product .mini-product-thumb img {
  width: 92px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .featured-product .mini-product-wrapper .single-mini-product .mini-product-thumb img {
    width: 72px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .featured-product .mini-product-wrapper .single-mini-product .mini-product-content {
    padding-left: 10px;
  }
}

.blog-wrapper {
  border: 1px solid #e6e6e6;
  border-radius: 0 3px 3px 3px;
  padding: 20px;
}

.single-blog {
  display: flex !important;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  .blog-image {
    width: 50%;
    padding: 0 15px;

    a {
      overflow: hidden;

      img {
        width: 100%;
        transition: all 0.3s linear;
      }
    }
  }

  .blog-content {
    width: 50%;
    padding: 0 15px;

    .date {
      color: #666;
      font-size: 22px;
      line-height: 35px;
      font-weight: 700;

      strong {
        font-size: 48px;
        color: #333;
      }
    }

    .title a {
      margin-top: 20px;
      display: block;
      font-size: 16px;
      color: #252525;
      text-transform: uppercase;
      line-height: 16px;
      position: relative;
      font-weight: 400;
    }

    p {
      line-height: 22px;
      font-size: 14px;
      padding: 0;
      margin-top: 20px;
    }

    .more {
      font-size: 15px;
      color: #666;
      margin-top: 20px;
    }
  }

  &:hover .blog-image a img {
    transform: scale(1.1);
  }
}

@media (max-width: 767px) {
  .single-blog .blog-image {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-blog .blog-image {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .single-blog .blog-content {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-blog .blog-content {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog .blog-content .date {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .single-blog .blog-content .date {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog .blog-content .date strong {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .single-blog .blog-content .date strong {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog .blog-content .title a {
    margin-top: 10px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog .blog-content p {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog .blog-content .more {
    margin-top: 10px;
  }
}

.blog-active .slick-arrow {
  width: 38px;
  height: 38px;
  line-height: 36px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  text-align: center;
  font-size: 16px;
  color: #333;
  position: absolute;
  top: -68px;
  right: -22px;
  transition: all .3s linear;
  background-color: #fff;
  z-index: 5;
  cursor: pointer;

  &.prev {
    right: 25px;
  }

  &:hover {
    background-color: $primColor;
    border-color: $primColor;
    color: #fff;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-active .slick-arrow {
    top: -45px;
    width: 34px;
    height: 34px;
    line-height: 32px;
  }
}

.brand-wrapper {
  border: 1px solid #e6e6e6;
  border-radius: 0 3px 3px 3px;
  padding: 13px 20px;

  .brand-items {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand-wrapper {
    padding: 28px 20px;
  }
}

.page-breadcrumb .breadcrumb {
  padding: 25px 0;
  margin-bottom: 0;
  background: 0 0;

  li {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;

    + li {
      margin-left: 10px;

      &::before {
        content: '\f054';
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        color: #333;
        font-size: 13px;
        padding-right: 10px;
      }
    }

    &.active {
      color: $primColor;
    }

    a {
      color: #333;
      transition: all 0.3s linear;

      &:hover {
        color: $primColor;
      }
    }
  }
}

.about-image img {
  width: 100%;
}

.about-content {
  .title {
    font-size: 32px;
    font-weight: 700;
    color: #333;
  }

  p {
    margin-top: 15px;
    font-size: 14px;
  }
}

.about-item {
  .title {
    font-size: 20px;
  }

  p {
    margin-top: 15px;
    font-size: 14px;
  }
}

.product-title-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .product-title {
    color: #333;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }

  .product-listing {
    font-size: 13px;
    font-weight: 600;
    color: #333;
    margin-top: 10px;
  }
}

.sortpagibar {
  border: 1px solid #e6e6e6;
  padding: 10px;
  padding-top: 0;
  margin-top: 20px;
  border-radius: 3px;

  .sortpagibar-left {
    display: flex;

    .nav {
      padding-top: 10px;

      li {
        margin-right: 5px;

        a {
          width: 30px;
          height: 30px;
          border: 0;
          margin-left: 0;
          display: block;
          line-height: 30px;
          background: #9e9e9e;
          border-radius: 3px;
          cursor: pointer;
          text-align: center;
          color: #fff;
          font-size: 21px;

          &.active {
            background-color: $primColor;
            color: #fff !important;
          }
        }
      }
    }

    .select {
      display: flex;
      margin-left: 40px;
      margin-top: 10px;

      label {
        padding: 6px 6px 0 0;
        font-size: 12px;
        color: #999;
        font-weight: 400;
        margin: 0;
      }

      .selector select {
        height: 27px;
        border: 1px solid #e6e6e6;
        font-size: 12px;
        padding: 0 10px;
      }
    }
  }

  .sortpagibar-compare button {
    border: 0;
    background: #333;
    color: #fff;
    text-transform: uppercase;
    padding: 0 15px;
    height: 27px;
    font-size: 11px;
    margin-top: 10px;

    i {
      margin-left: 5px;
    }
  }
}

@media (max-width: 767px) {
  .sortpagibar .sortpagibar-left {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sortpagibar .sortpagibar-left {
    display: flex;
  }
}

@media (max-width: 767px) {
  .sortpagibar .sortpagibar-left .select {
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sortpagibar .sortpagibar-left .select {
    margin-left: 30px;
  }
}

.shop-product {
  border: 1px solid #e6e6e6;
  padding: 20px;
  padding-top: 0;
  border-radius: 3px;
}

.pagination-area {
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 3px;

  .pagination-count-list {
    display: flex;
    align-items: center;

    .count {
      font-size: 12px;
      color: #999;
      font-weight: 400;
      margin-top: 10px;
      margin-right: 40px;
    }

    .list {
      margin-top: 10px;
    }
  }

  .pagination-compare {
    text-align: center;

    button {
      border: 0;
      background: #333;
      color: #fff;
      text-transform: uppercase;
      padding: 0 15px;
      height: 27px;
      font-size: 11px;
      margin-top: 10px;

      i {
        margin-left: 5px;
      }
    }
  }
}

@media (max-width: 767px) {
  .pagination-area .pagination-count-list {
    display: block;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pagination-area .pagination-count-list {
    display: flex;
  }
}

@media (max-width: 767px) {
  .pagination-area .pagination-count-list .count {
    margin-right: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pagination-area .pagination-count-list .count {
    margin-right: 30px;
  }
}

.shop-sidebar .shop-sidebar-title {
  font-weight: 700;
  font-size: 18px;
  color: #333;
  position: relative;
  text-transform: uppercase;
  position: relative;
  line-height: 18px;
}

.checkbox-items {
  border: 1px solid #e6e6e6;
  padding: 20px;
  border-radius: 3px;
  margin-top: 20px;

  li + li {
    margin-top: 10px;
  }
}

.sidebar-checkbox {
  padding-bottom: 2px;

  input[type=checkbox] + label {
    display: block;

    strong {
      font-weight: 400;
      display: block;
      float: right;
    }
  }
}

.sidebar-price .sidebar-price-range {
  margin-top: 40px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  border-radius: 3px;
  margin-top: 20px;

  p {
    font-size: 15px;
    color: #666;

    input {
      font-size: 15px;
      color: #666;
      border: 0;
      background: 0 0;
      display: inline-block;
      width: 110px;
    }
  }

  #slider-range {
    width: 100%;
    height: 5px;
    border: none;
    margin-top: 20px;
    background: #ccc;

    .ui-widget-header {
      background: $primColorNigger;
    }

    .ui-slider-handle {
      top: -5px;
      width: 15px;
      height: 15px;
      background: $primColor;
      border-radius: 100%;
      box-shadow: none;
      cursor: pointer;
      border: 0;
      margin-left: 0;

      &:focus {
        outline: 0;
      }

      &:last-child {
        margin-left: -15px;
      }
    }
  }

  .main-btn {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    background-color: $primColor;
    color: #fff;
    margin-top: 15px;

    &:hover {
      background-color: #222;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-price .sidebar-price-range p input {
    font-size: 14px;
  }
}

.sidebar-banner a {
  display: block;
  position: relative;

  &::after, &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    background-color: rgba(51, 51, 51, 0.1);
    top: 0;
    left: 0;
    transition: all .3s linear;
    border-radius: 4px;
  }

  &::after {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }

  &:hover {
    &::after, &::before {
      width: 100%;
      height: 100%;
    }
  }

  img {
    width: 100%;
    border-radius: 4px;
  }
}

.shop-image {
  .view-image-wrapper {
    position: relative;
    border: 1px solid #e6e6e6;
    text-align: center;
    padding-bottom: 20px;

    .btn-gallery {
      padding: 10px 15px;
      text-align: center;
      line-height: 22px;
      color: #777676;
      text-align: center;
      font-weight: 700;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);

      i {
        margin-left: 10px;
        color: silver;
      }
    }
  }

  .view-image .single-image {
    border-radius: 4px;

    img {
      display: inline-block;
      width: 100%;
      border-radius: 4px;
    }
  }

  .shop-thumb {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    .thumb-col {
      padding-left: 10px;
      padding-right: 10px;
    }

    .single-thumb {
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s linear;

      img {
        width: 100%;
        border-radius: 4px;
      }
    }

    .slick-current .single-thumb {
      border-color: $primColor;
    }

    .slick-arrow {
      width: 38px;
      height: 38px;
      line-height: 36px;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      text-align: center;
      font-size: 16px;
      color: #333;
      position: absolute;
      top: 50%;
      right: -48px;
      transition: all .3s linear;
      transform: translateY(-50%);
      background-color: #fff;
      z-index: 5;
      cursor: pointer;

      &.prev {
        right: auto;
        left: -48px;
      }

      &:hover {
        background-color: $primColor;
        border-color: $primColor;
        color: #fff;
      }
    }
  }

  .gallery-hidden {
    overflow: hidden;
    display: none;
    visibility: hidden;
  }
}

@media (max-width: 767px) {
  .shop-image .shop-thumb {
    width: 200px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-image .shop-thumb {
    width: 320px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-image .shop-thumb .slick-arrow {
    width: 34px;
    height: 34px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .shop-image .shop-thumb .slick-arrow {
    width: 30px;
    height: 30px;
    line-height: 28px;
    right: -30px;
  }
}

@media (max-width: 767px) {
  .shop-image .shop-thumb .slick-arrow.prev {
    left: -30px;
  }
}

.shop-gallery-image {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
  display: flex;
  flex-wrap: wrap;

  .gallery-col {
    padding: 10px;
    width: 50%;
  }

  .single-image {
    border: 1px solid #e6e6e6;

    img {
      width: 100%;
    }
  }
}

.shop-sticky-image .single-image {
  border: 1px solid #e6e6e6;

  + .single-image {
    margin-top: 20px;
  }

  img {
    width: 100%;
  }
}

.shop-single-content {
  &.shop-sticky {
    position: sticky;
    top: 90px;
    left: 0;
    width: 100%;
  }

  .product-title {
    color: #555;
    font-size: 24px;
    font-weight: 700;
  }

  .product-reviews {
    padding-top: 10px;
    padding-bottom: 10px;

    li {
      font-size: 12px;
      color: #ddd;
      display: inline-block;

      &.rating-on {
        color: #f2b309;
      }
    }
  }

  .product-condition, .product-reference {
    font-size: 13px;
    margin-top: 6px;
  }

  .product-condition strong, .product-reference strong {
    color: #444;
  }

  .product-price {
    .sale-price {
      color: #333;
      font-size: 30px;
      display: inline-block;
      font-weight: 700;
    }

    .regular-price {
      font-size: 18px;
      font-weight: 400;
      color: #666;
      text-decoration: line-through;
      display: inline-block;
      margin-left: 5px;
    }
  }

  .description {
    border-top: 1px solid #e6e6e6;
    padding-top: 25px;
    margin-top: 25px;
  }

  .product-quantity {
    margin-top: 45px;
    display: flex;
    align-items: center;

    .title {
      font-size: 14px;
      color: #333;
      font-weight: 700;
    }

    .quantity {
      flex: 1;
      padding-left: 10px;

      input {
        width: 60px;
        height: 40px;
        border: 1px solid #e6e6e6;
        padding: 0 10px;
        text-align: center;
      }

      button {
        width: 30px;
        height: 40px;
        background-color: transparent;
        border: 1px solid #e6e6e6;
      }
    }
  }

  .product-details-table {
    margin-top: 30px;

    .table {
      margin-bottom: 0;

      tbody tr td {
        padding: 10px 15px;
        width: 33.33%;
        text-align: center;
        border: 1px solid #eceff8;
        vertical-align: middle;

        a, &.amount {
          font-size: 14px;
          color: #222;
          text-transform: capitalize;
        }

        .product-quantity {
          margin-top: 0;
        }
      }
    }
  }

  .product-btn {
    padding-top: 15px;

    .main-btn {
      height: 40px;
      line-height: 40px;
      padding: 0 25px;
      font-size: 14px;
      background-color: $redColor;

      &:hover {
        color: #fff;
        background-color: #333;
      }
    }
  }

  .usefull-link {
    margin-left: -15px;
    padding-top: 15px;

    li {
      display: inline-block;
      padding-left: 15px;

      a {
        color: #828282;
        font-weight: 400;
        position: relative;
        line-height: 22px;
        display: inline-block;
        font-size: 13px;
        transition: all 0.3s linear;

        i {
          margin-right: 5px;
        }

        &:hover {
          color: $primColor;
        }
      }
    }
  }

  .product-size {
    margin-top: 25px;
    display: flex;
    align-items: center;

    .title {
      font-weight: 400;
      font-size: 13px;
      margin-right: 10px;
      line-height: 35px;
      color: #444;
    }

    select {
      width: 228px;
      height: 30px;
      font-size: 14px;
      padding: 0 10px;
      border: 1px solid #e6e6e6;
      color: #666;
    }
  }

  .color-wrapper {
    .title {
      font-weight: 400;
      font-size: 13px;
      margin-right: 10px;
      line-height: 35px;
      color: #444;
    }

    .product-color {
      display: flex;

      li {
        padding: 2px;
        border: 1px solid transparent;
        display: flex;

        + li {
          margin-left: 5px;
        }

        span {
          width: 22px;
          height: 22px;
          transition: all .3s linear;
          cursor: pointer;
        }

        &.active {
          border-color: #333;
          box-shadow: 2px 2px 4px 0 rgba(51, 51, 51, 0.25);
        }
      }
    }
  }

  .available {
    margin-top: 10px;

    p {
      font-size: 13px;
      color: #666;

      .available-statut {
        line-height: 18px;
        font-weight: 700;
        color: #fff;
        background-color: #333;
        padding: 3px 8px 4px;
        margin-left: 5px;
      }
    }
  }

  .product-share {
    display: flex;
    align-items: center;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 25px 0;
    margin-top: 25px;

    .title {
      font-size: 14px;
      color: #333;
      font-weight: 700;
    }

    .social li {
      display: inline-block;
      margin-left: 25px;

      a {
        font-size: 18px;
        color: #333;
      }
    }
  }
}

@media (max-width: 767px) {
  .shop-single-content .product-title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .shop-single-content .product-share .social li {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .shop-single-content .product-share .social li a {
    font-size: 16px;
  }
}

.shop-info {
  
  .nav {
    margin-left: -10px;
    margin-right: -10px;

    li {
      padding: 10px;
      padding-bottom: 0;

      a {
        padding: 15px 15px;
        white-space: nowrap;
        text-align: center;
        background-color: #e6e6e6;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        color: #333;
        transition: all .3s linear;
        display: block;

        &.active {
          background-color: $primColor;
          color: #fff;
        }
      }
    }
  }
 
  .tab-content {
    border: 1px solid #e6e6e6;
    padding: 30px 30px;
    overflow-x: auto;
    p {
      font-size: 15px;
    }
  }

  .data {
    margin: -31px -30px; 
    .table {
      margin-bottom: 0;

      tbody tr {
        td {
          border-top: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 20px 11px;
          color: #333;
          font-size: 15px;
        }

        th {
          border-top: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 20px 11px;
          color: #333;
          font-size: 15px;
          width: 20%;
          font-weight: 700;
        }

        td {
          width: 80%;
          border-left: 1px solid #e6e6e6;
        }
      }
    }
  }

  .product-review {
    .review-comment {
      .comment > li {
        padding: 20px 0;
        border-bottom: 1px solid #e6e6e6;

        &:first-child {
          padding-top: 0;
        }
      }

      .single-review-comment {
        display: flex;

        .review-author img {
          width: 70px;
          border-radius: 50%;
        }

        .review-content {
          flex: 1;
          padding-left: 30px;

          p {
            font-size: 15px;
            position: relative;
            padding-bottom: 15px;

            &::before {
              width: 50px;
              height: 1px;
              background-color: #222;
              position: absolute;
              content: '';
              left: 0;
              bottom: 0;
            }
          }

          .review-name-rating {
            display: flex;
            justify-content: space-between;
            padding-top: 5px;

            .review-name {
              text-transform: uppercase;
              color: #222;
              font-size: 15px;
              font-weight: 400;
              margin-top: 10px;
            }

            .review-rating {
              margin-top: 15px;

              li {
                display: inline-block;
                font-size: 12px;
                color: #666;

                &.rating-on {
                  color: #f5b223;
                }
              }
            }
          }
        }
      }
    }

    .review-form {
      .form-title {
        font-weight: 700;
        color: #333;
        font-size: 20px;
      }

      .rating-star {
        padding-top: 10px;

        a {
          &::before {
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            font-size: 12px;
            color: #666;
            transition: all 0.3s linear;
          }

          + a {
            margin-left: 15px;
          }

          &.star-1::before {
            content: "";
          }

          &.star-2::before {
            content: "";
          }

          &.star-3::before {
            content: "";
          }

          &.star-4::before {
            content: "";
          }

          &.star-5::before {
            content: "";
          }

          &.active::before, &:hover::before {
            color: #f5b223;
          }
        }
      }

      .review-textarea {
        margin-top: 15px;

        label {
          font-size: 15px;
          color: #333;
          text-transform: uppercase;
        }

        input, textarea {
          width: 100%;
          height: 60px;
          max-width: 100%;
          vertical-align: top;
          padding: 0 20px;
          border: 1px solid #e6e6e6;
          transition: all 0.3s linear;
        }

        input:focus {
          border-color: $primColor;
        }

        textarea {
          &:focus {
            border-color: $primColor;
          }

          height: 200px;
          padding-top: 10px;
          resize: none;
        }
      }

      .review-btn {
        margin-top: 30px;

        .main-btn {
          width: 160px;
        }
      }

      .review-checkbok {
        margin-top: 25px;

        input[type=checkbox] {
          display: none;

          + label {
            position: relative;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            color: #333;

            span {
              width: 14px;
              height: 14px;
              border: 1px solid #e6e6e6;
              position: relative;
              border-radius: 3px;
              top: 5px;
              margin-right: 5px;
              transition: all 0.3s linear;

              &::before {
                content: '\f00c';
                font-family: "Font Awesome 5 Pro";
                font-weight: 400;
                font-size: 12px;
                color: #333;
                transition: all .3s linear;
                position: relative;
                top: -5px;
                opacity: 0;
                visibility: hidden;
              }
            }
          }

          &:checked + label span {
            background-color: $primColor;
            border-color: $primColor;

            &::before {
              color: #fff;
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .shop-info .nav li {
    width: 100%;
  }
   .shop-info iframe {
     width: 100%;
   }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-info .nav li {
    width: auto;
  }
}

@media (max-width: 767px) {
  .shop-info .nav li a {
    padding: 10px 15px;
    width: 100%;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
}

@media (max-width: 767px) {
  .shop-info .product-review .review-comment .single-review-comment {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-info .product-review .review-comment .single-review-comment {
    display: flex;
  }
}

@media (max-width: 767px) {
  .shop-info .product-review .review-comment .single-review-comment .review-content {
    padding-left: 0;
    padding-top: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-info .product-review .review-comment .single-review-comment .review-content {
    padding-left: 30px;
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .shop-info .product-review .review-form .rating-star a + a {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-info .product-review .review-form .rating-star a + a {
    margin-left: 15px;
  }
}

.blog-item, .blog-item-list {
  .blog-image a {
    overflow: hidden;
    display: block;

    img {
      width: 100%;
      transition: all 0.3s linear;
    }
  }

  .blog-content {
    margin-top: 25px;

    .blog-title a {
      font-size: 18px;
      font-weight: 500;
      color: #333;
      transition: all 0.3s linear;

      &:hover {
        color: $primColor;
      }
    }

    .meta {
      margin-top: 15px;

      li {
        display: inline-block;
        margin-right: 28px;

        &:last-child {
          margin-right: 0;
        }

        a {
          font-size: 14px;
          color: #666;
          transition: all 0.3s linear;

          &:hover {
            color: $primColor;
          }
        }
      }
    }

    p {
      font-size: 15px;
      margin-top: 20px;
    }

    .more {
      color: #666;
      font-size: 14px;
      transition: all .3s linear;
      margin-top: 20px;

      &:hover {
        color: $primColor;
      }
    }
  }

  &.blog-gallery .blog-image {
    a {
      overflow: hidden;
      display: block;

      img {
        width: 100%;
        transition: all 0.3s linear;
      }
    }

    &.gallery-active {
      .slick-arrow {
        width: 38px;
        height: 38px;
        line-height: 36px;
        border-radius: 4px;
        border: 1px solid #e6e6e6;
        text-align: center;
        font-size: 16px;
        color: #333;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        transition: all .3s linear;
        background-color: #fff;
        z-index: 5;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;

        &.next {
          left: auto;
          right: 10px;
        }

        &:hover {
          background-color: $primColor;
          border-color: $primColor;
          color: #fff;
        }
      }

      &:hover .slick-arrow {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:hover .blog-image a img {
    transform: scale(1.1);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-item .blog-content .meta li {
    margin-right: 18px;
  }
}

@media (max-width: 767px) {
  .blog-item .blog-content .meta li {
    margin-right: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-item-list .blog-content .meta li {
    margin-right: 18px;
  }
}

@media (max-width: 767px) {
  .blog-item-list .blog-content .meta li {
    margin-right: 18px;
  }
}

.blog-sidebar .blog-sidebar-title {
  font-weight: 700;
  font-size: 18px;
  color: #333;
  position: relative;
  text-transform: uppercase;
  position: relative;
  line-height: 18px;
}

.blog-sidebar-search .search-form {
  margin-top: 20px;
  position: relative;

  input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    padding-right: 50px;
    border: 1px solid #e6e6e6;
    transition: all .3s linear;
    font-size: 15px;

    &:focus {
      border-color: $primColor;
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border: 0;
    background: 0 0;
    color: #666;
  }
}

.blog-sidebar-category .category-items {
  border: 1px solid #e6e6e6;
  padding: 20px;
  border-radius: 3px;
  margin-top: 20px;

  li {
    + li {
      margin-top: 10px;
    }

    a {
      display: block;
      font-size: 15px;
      font-weight: 400;
      color: #666;
      transition: all 0.3s linear;

      span {
        float: right;
        display: block;
      }

      &:hover {
        color: $primColor;
      }
    }
  }
}

.blog-sidebar-post .sidebar-post-items {
  border: 1px solid #e6e6e6;
  padding: 20px;
  border-radius: 3px;
  margin-top: 20px;

  li {
    + li {
      margin-top: 20px;
    }

    .single-mini-post {
      .post-thumb a img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        object-position: center;
      }

      .mini-post-content {
        padding-left: 20px;

        .post-title a {
          font-size: 16px;
          font-weight: 500;
          color: #333;
          transition: all 0.3s linear;

          &:hover {
            color: $primColor;
          }
        }

        .author {
          font-size: 14px;
          color: #666;
          transition: all .3s linear;
          margin-top: 5px;

          &:hover {
            color: $primColor;
          }
        }
      }
    }
  }
}

.blog-sidebar-tags .tags-items {
  border: 1px solid #e6e6e6;
  padding: 20px;
  padding-top: 10px;
  border-radius: 3px;
  margin-top: 20px;
  margin-left: -3px;
  margin-right: -3px;

  a {
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    color: #333;
    border: 1px solid #e6e6e6;
    padding: 0 14px;
    transition: all .3s linear;
    margin-top: 10px;
    margin-left: 3px;
    margin-right: 3px;

    &:hover {
      border-color: $primColor;
      background-color: $primColor;
      color: #fff;
    }
  }
}

.blog-details {
  img {
    width: 100%;
  }

  .blog-title {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin-top: 25px;
  }

  .meta {
    margin-top: 15px;

    li {
      display: inline-block;
      margin-right: 28px;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 14px;
        color: #666;
        transition: all 0.3s linear;

        &:hover {
          color: $primColor;
        }
      }
    }
  }

  p {
    padding-top: 25px;
    font-size: 15px;
  }

  .blockquote {
    margin-bottom: 0;
    margin-top: 25px;
    padding-left: 50px;

    p {
      font-style: italic;
      padding: 15px 14px;
      border-left: 3px solid $primColor;
    }
  }

  .blog-tags-share {
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;

    .blog-tags {
      margin-top: 30px;

      .title {
        font-size: 14px;
        font-weight: 500;
        color: #222;
      }

      .tag-list li {
        display: inline-block;
        margin-left: 3px;

        a {
          font-size: 14px;
          font-weight: 400;
          color: #666;
        }
      }
    }

    .blog-share {
      margin-top: 30px;

      .title {
        ont-size: 14px;
        font-weight: 500;
        color: #222;
      }

      .social li {
        display: inline-block;
        margin-left: 15px;

        a {
          font-size: 16px;
          color: #666;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .blog-details .blog-title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details .meta li {
    margin-right: 18px;
  }
}

@media (max-width: 767px) {
  .blog-details .meta li {
    margin-right: 18px;
  }
}

@media (max-width: 767px) {
  .blog-details .blockquote {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details .blockquote {
    padding-left: 50px;
  }
}

.comment-title {
  font-size: 20px;
  color: #333;
  font-weight: 700;
}

.blog-comment .blog-comment-items {
  padding-top: 10px;

  li {
    margin-top: 30px;

    .single-comment {
      .comment-thumb img img {
        border-radius: 50%;
      }

      .comment-content {
        padding-left: 30px;
        position: relative;

        .name {
          font-size: 16px;
          font-weight: 600;
          color: #333;
        }

        .date {
          font-size: 14px;
          color: #666;
          margin-top: 5px;
        }

        p {
          margin-top: 20px;
        }

        .reply {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 16px;
          color: #333;
          transition: all 0.3s linear;

          &:hover {
            color: $primColor;
          }
        }
      }
    }
  }

  .reply-comment {
    padding-left: 135px;
  }
}

@media (max-width: 767px) {
  .blog-comment .blog-comment-items li .single-comment .comment-content {
    padding-top: 25px;
    padding-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-comment .blog-comment-items li .single-comment .comment-content {
    padding-top: 0;
    padding-left: 25px;
  }
}

@media (max-width: 767px) {
  .blog-comment .blog-comment-items li .single-comment .comment-content .reply {
    position: relative;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-comment .blog-comment-items li .single-comment .comment-content .reply {
    position: absolute;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .blog-comment .blog-comment-items .reply-comment {
    padding-left: 0;
  }
}

.form-message {
  &.error, &.success {
    font-size: 16px;
    color: #222;
    background: #ddd;
    padding: 10px 15px;
    margin-left: 15px;
    margin-top: 15px;
  }

  &.error {
    color: red;
  }
}

.contact-title .title {
  font-size: 36px;
  font-weight: 400;
  color: #222;
  font-family: Montserrat, sans-serif;
  position: relative;
}

@media (max-width: 767px) {
  .contact-title .title {
    font-size: 28px;
  }
}

.contact-map #contact-map {
  width: 100%;
}

.my-account-menu .account-menu-list {
  background-color: #f7f7f7;

  li a {
    width: 100%;
    padding: 10px 30px;
    font-size: 15px;
    color: #666;
    margin-top: -1px;
    transition: all 0.3s linear;

    i {
      margin-right: 5px;
    }

    &.active, &:hover {
      background-color: $primColor;
      color: #fff;
    }
  }
}

.my-account-tab .account-title {
  font-size: 24px;
  font-weight: 500;
  color: #333;
}

.account-table .table {
  thead tr th {
    border: 1px solid #e6e6e6;
    font-size: 15px;
    font-weight: 400;
    color: #333;
    padding: 8px 15px;

    &.name {
      min-width: 140px;
    }

    &.date {
      min-width: 130px;
    }
  }

  tbody tr td {
    font-size: 15px;
    color: #333;
    font-weight: 400;
    vertical-align: middle;
    border: 1px solid #e6e6e6;
    padding: 0;

    a {
      padding: 0 15px;
      height: 45px;
      line-height: 45px;
      color: #333;
    }
  }
}

.my-account-dashboard {
  .welcome-dashboard {
    margin-top: 10px;

    p {
      font-size: 15px;
      color: #666;

      strong {
        font-weight: 500;
      }

      a {
        color: #222;
        transition: all 0.3s linear;

        &:hover {
          color: $primColor;
        }
      }
    }
  }

  p {
    color: #666;
  }
}

.my-account-payment p {
  color: #666;
  font-weight: 500;
  font-size: 15px;
}

.my-account-address .account-address {
  .name {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }

  p {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
  }

  .main-btn {
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
    font-size: 15px;
    margin-top: 20px;
  }
}

.my-account-details .account-details .title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

.login-register-wrapper {
  border: 2px solid #e6e6e6;
  padding: 25px 30px;

  .title {
    font-size: 24px;
  }

  p {
    font-size: 15px;
    color: #333;
    vertical-align: top;

    a {
      color: $primColor;
    }
  }

  .checkbox {
    position: relative;

    input[type=checkbox] + label {
      position: absolute;
      top: 0;
      left: 0;
    }

    p {
      line-height: 24px;
      padding-left: 30px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrapper {
    padding: 30px 20px;
  }
}
@media (max-width: 575px) {
.header-top{
  .header-top-wrapper{
    p{
      display: grid;
      border-bottom: 1px solid #2695ca;
    }
  }
} 
}
@media (max-width: 767px) {
  .login-register-wrapper {
    padding: 20px 15px;
  }
}

@media (max-width: 767px) {
  .login-register-wrapper .title {
    font-size: 18px;
  }
}

.shopping-title {
  font-weight: 700;
  font-size: 18px;
  color: #333;
  position: relative;
  text-transform: uppercase;
  position: relative;
  line-height: 18px;
}

.shopping-cart-table .table {
  margin-bottom: 0;

  thead tr th, .cell {
    padding: 8px 15px;
    border: 1px solid #e6e6e6;
    font-size: 16px;
    font-weight: 500;
    color: #222;

    &.product {
      min-width: 242px;
    }
  }

  tbody tr td, .cell {
    border: 1px solid #e6e6e6;
    vertical-align: middle;
    display: -webkit-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .product-image img {
      width: 125px;
    }

    .product-title .title a {
      font-size: 16px;
      font-weight: 500;
      color: #222;
    }

    .product-price .price {
      font-size: 16px;
      color: #222;
    }

    .product-quantity .quantity {
      input {
        width: 30px;
        height: 30px;
        border: 1px solid #e6e6e6;
        padding: 0 5px;
        text-align: center;
      }

      button {
        width: 20px;
        height: 30px;
        background-color: transparent;
        border: 1px solid #e6e6e6;
      }
    }

    .product-total .total-amount {
      font-size: 16px;
      color: #222;
    }

    .product-delete {
      text-align: center;

      a {
        color: #222;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shopping-cart-table .table tbody tr td .product-title .title a {
    font-size: 14px;
    line-height: 15px;
  }
}

@media (max-width: 767px) {
  .shopping-cart-table .table tbody tr td .product-title .title a {
    font-size: 14px;
    line-height: 15px;
  }
}






.cart-btn {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;

  .cart-btn-left, .cart-btn-right {
    margin-top: 15px;
  }

  .main-btn-2 {
    background-color: transparent;
    border: 1px solid #e6e6e6;
    line-height: 48px;
    color: #333;

    &:hover {
      border-color: $primColor;
      background-color: $primColor;
      color: #fff;
    }
  }

  .main-btn {
    padding: 0 30px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 767px) {
  .cart-btn {
    display: block;
  }
}

@media (max-width: 767px) {
  .cart-btn .main-btn {
    padding: 0 20px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
  }
}

.cart-title {
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #333;
    position: relative;
    text-transform: uppercase;
    position: relative;
    line-height: 18px;
  }

  p {
    font-size: 15px;
    color: #666;
    margin-top: 8px;
  }
}

.cart-form {
  p {
    font-size: 14px;
    color: #666;
  }

  .form-select2, .single-form {
    margin-top: 10px;
  }

  .cart-form-btn {
    text-align: right;
    margin-top: 20px;

    .btn-cart {
      padding: 5px 0;
      background: 0 0;
      text-transform: uppercase;
      font-weight: 700;
      color: #222;
      font-size: 14px;
      border: 0;
      border-bottom: 2px solid #222;
    }
  }
}

.cart-totals .cart-total-table {
  background-color: #f7f7f7;
  padding: 15px 20px;

  .table {
    margin-bottom: 0;

    tbody tr td {
      padding: 5px 0;
      border-top: 0;
    }
  }

  .value {
    font-size: 14px;
    font: 400px;
    color: #222;
    text-transform: capitalize;
  }

  .price {
    font-size: 14px;
    font: 400px;
    color: #222;
  }
}

.empty-cart {
  span {
    font-size: 60px;
    color: #333;
    line-height: 60px;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: #333;
    position: relative;
    text-transform: uppercase;
    position: relative;
    line-height: 24px;
    margin-top: 25px;
  }

  .main-btn {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .empty-cart .title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .empty-cart .title {
    font-size: 16px;
  }
}

.checkout-info {
  .info-header {
    padding: 14px 30px;
    position: relative;
    background-color: #eeeff2;
    list-style: none outside !important;
    width: auto;
    word-wrap: break-word;
    color: #333;
    font-size: 15px;

    strong {
      font-weight: 700;
      color: #333;
    }

    i {
      font-size: 12px;
      color: #6dde6d;
      margin-right: 10px;
    }

    a {
      color: #333;
      transition: all 0.3s linear;

      &:hover {
        color: $primColor;
      }
    }

    &.error i {
      color: #c43002;
    }
  }

  .card-body {
    padding: 0;

    p {
      font-size: 15px;
      color: #666;
      margin-top: 15px;
    }

    .single-form {
      margin-top: 15px;

      .main-btn {
        padding: 0 30px;
      }

      .checkbox {
        margin-left: 20px;
      }
    }

    .forget {
      margin-top: 15px;

      a {
        font-size: 15px;
        font-weight: 400;
        color: #666;
        transition: all 0.3s linear;

        &:hover {
          color: $primColor;
        }
      }
    }
  }
}

.checkout-title .title {
  font-weight: 700;
  font-size: 18px;
  color: #333;
  position: relative;
  text-transform: uppercase;
  position: relative;
  line-height: 18px;
}

.checkout-form {
  .checkbox {
    margin-top: 25px;

    input[type=checkbox] {
      + label {
        color: #333;

        span {
          border: 1px solid #333;
        }
      }

      &:checked + label span {
        background-color: transparent;
        border-color: #333;

        &::before {
          color: #333;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .checkout-account, .checkout-shipping {
    display: none;
  }

  .checkout-note textarea {
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    height: 120px;
  }
}

.checkout-review-order {
  border: 2px solid #e6e6e6;
  padding: 40px;

  .table {
    margin-bottom: 0;

    thead tr th {
      padding: 10px 0;
      border-top: 0;
      border-bottom: 1px solid #e6e6e6;
      font-weight: 400;
      font-size: 14px;
      color: #333;
      vertical-align: middle;

      &.Product-price {
        text-align: right;
      }
    }

    tbody tr {
      td {
        padding: 5px 0;
        border-top: 0;
        vertical-align: middle;

        p {
          font-weight: 400;
          font-size: 14px;
          color: #333;
        }

        &.Product-price {
          text-align: right;
        }
      }

      &:first-child td {
        padding-top: 20px;
      }

      &:last-child td {
        padding-bottom: 20px;
      }
    }

    tfoot tr td {
      padding: 10px 0;
      vertical-align: middle;

      p {
        font-weight: 400;
        font-size: 14px;
        color: #333;
      }

      &.Product-price {
        text-align: right;
      }

      &.total-price {
        text-align: right;

        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .checkout-review-order {
    padding: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .checkout-review-order {
    padding: 40px;
  }
}

.checkout-payment {
  margin-top: 30px;

  .single-payment .payment-radio {
    label {
      .payment {
        width: 140px;
        padding: 0 2px;
      }

      a {
        color: #333;
        text-decoration: underline;
        text-transform: capitalize;
      }
    }

    .payment-details {
      display: none;
      padding: 10px 0;

      p {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .checkout-btn {
    margin-top: 20px;
  }
}

.compare-table .table {
  margin-bottom: 0;

  tbody tr {
    th {
      width: 200px;
      text-align: center;
      padding: 15px 15px;
      border: 1px solid #e6e6e6;
      font-size: 16px;
      font-weight: 500;
      color: #333;
      vertical-align: middle;
    }

    td {
      padding: 15px 15px;
      text-align: center;
      border: 1px solid #e6e6e6;
      color: #333;
      font-size: 16px;

      .product-image-title {
        .product-image img {
          display: block;
        }

        .category {
          color: #666;
          font-size: 15px;
          display: block;
          margin-top: 25px;

          &:hover {
            color: $primColor;
          }
        }

        .title a {
          font-size: 16px;
          color: #333;
          font-weight: 500;
          margin-top: 15px;
        }
      }

      .description {
        text-align: left;

        p {
          font-size: 15px;
        }
      }

      .main-btn {
        height: 50px;
        line-height: 50px;
        letter-spacing: 0;
      }

      .delete {
        font-size: 18px;
        color: #333;
      }

      .rating li {
        display: inline-block;
        font-size: 14px;
        color: #e1e1e1;

        &.rating-on {
          color: #f3cd1e;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .compare-table .table tbody tr th {
    padding: 20px 15px;
  }

  .compare-table .table tbody tr td {
    padding: 20px 15px;
    font-size: 16px;
  }
}

.order-tracking-wrapper {
  p {
    font-size: 15px;
    color: #666;
  }

  .single-form .main-btn {
    width: 160px;
  }
}

.product-documents__list {
  display: flex;
  flex-direction: column;

  .list__item {
    .item__link {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      box-shadow: 0 0 3px rgba(#000, .75);
      transition: box-shadow .2s ease-in-out;
      border-radius: 3px;
      overflow: hidden;

      &:after {
        content: ' ';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width .35s ease-in-out;
        background-color: $primColor;
      }
    }

    .item__icon {
      color: #fff;
      font-size: 24px;
      padding: 10px 15px;
      background-color: $primColor;

      &::before {
        content: "\f15b";
      }
    }

    .item__name {
      white-space: nowrap;
      text-overflow: clip;
      overflow: hidden;
      font-size: 22px;
      padding: 0 15px;
      color: $primColor;
      transition: color .2s ease-in-out;
    }

    &:hover {
      .item__link {
        box-shadow: 0 0 7px rgba(#000, .35);

        &:after {
          width: 100%;
        }
      }

      .item__name {
        color: #fff;
      }

      .item__icon::before {
        content: "\f56d"
      }
    }
  }
}

#delivery.tab-pane .more-info img.del-img {
  width: 100%;
  margin-right: 30px;
}
.shop-info .tab-content #content {
  display: inline-block;
}

//определение геопозиции
#jGrowl{
  display: none;
}


.product-title-listing .product-title {
  font-size: 40px !important;
}

.shop-content__bottom ol, .shop-content__bottom ul {
  margin: 10px !important;
  margin-left: 50px !important;
  list-style-type: unset !important;
}

.shop-content__bottom p {
   margin: 10px !important;
}

.shop-content__bottom h2, .shop-content__bottom .h2, .shop-content__bottom h3, .shop-content__bottom .h3, .shop-content__bottom h4, .shop-content__bottom .h4, .shop-content__bottom h5, .shop-content__bottom .h5, .shop-content__bottom h6, .shop-content__bottom .h6{
  padding-top: 20px;
  padding-bottom: 20px;
  word-break: break-word;
}

@media (max-width: 462px) { 
  .product-item .product-content .product-name a{
    font-size: 13px;
  }
  .single-slider .slider-banner-content .main-title{
    font-size: 14px !important;
    margin-top: 5px; 
  }
 /* h2, .h2 {
    font-size: 30px !important;
  }*/
  .product-title-listing .product-title {
    font-size: 25px !important; 
  }
}    
 
.navbar-category .menu-items > li .navbar-mega-sub-menu .mega-sub-item {
  padding-right: 20px;
}

header .seo-work-time li{
  color: #777;
  text-decoration: none;
  font-size: 12px;
  padding: 0 10px;
  line-height: 30px;
  transition: all .3s linear;
  display: block;
}

body{
  background-image: url('/retro-dark.png');
}

.shop-content__container .shop__top__content ul,
.shop-info .tab-content #content .more-info ul,
.home-description ul,
.shop-content__container .shop__top__content ol,
.shop-info .tab-content #content .more-info ol,
.home-description ol
{
  list-style-type: unset !important;
  margin-left: 40px !important;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.shop-content__container .shop__top__content p, .shop-info .tab-content #content .more-info p, .home-description p {
  margin-bottom: 0 !important;
  text-indent: 1.5em !important;
  margin-top: 0 !important;
}  

.header-middle{ 
  background-color: #F2F4F6 !important;
}

.header-cart .dropdown .cart {
  color: #01BEEF !important;
} 

.shop-categories .shop-categories__items .items__item .item__image img{
  max-width: 150px !important;
}
.ion-android-menu:before {
  cursor: pointer !important;
  position: relative;
  z-index: 9999999;
}

iframe { 
  width: 100% !important;
  height: 500px !important;
  margin-bottom: 20px !important;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .navbar-category .menu-items li a {
         line-height: 26px;
    }
}  

@media (min-width: 1200px) and (max-width: 1599px) {
    .navbar-category .menu-items li a {
        line-height: 23px;
    }
}

.product-arrivals{
  .slick-slider{
    .slick-list{
      margin: 0 20px;
    }
    .slick-arrow{
      display: inline-block;
      font-weight: 500;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 0;
      padding: 0 7px;
      height: 50px;
      line-height: 50px;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      z-index: 5;
      transition: .4s linear;
      background-color: #0083c1;
    } 
  }
}


.shopping-cart-table{
  .table{
    td, th{
      padding: 5px !important;
    }
  }
} 
