@import "../variables";

.shop-categories {
  .shop-categories__items {
    margin-top: 20px;

    .items__item {
      width: 100%;
      padding: 5px;
      margin-bottom: 25px;
      text-align: center;
      border: 1px solid transparent;
      //transition: border-color .2s ease-in-out;
      border-radius: 3px;
      transition: .2s; 
      .item__image {
        position: relative;

        img {
          width: 100%;
        }

        .image__count {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          color: #fff;
          font-size: 24px;
          font-weight: 600;
          background-color: rgba($primColorNigger, .35);
          backdrop-filter: blur(15px) saturate(180%);
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          transition: opacity .2s ease-in-out;
        }
      }

      .item__name {
        text-align: center;
        font-size: 16px;
        margin-top: 15px;
        color: #656565;
        transition: color .2s ease-in-out;

        &:active {
          color: $primColor;
        }
      }

      &:hover {

        transform: scale(1.2); 
        
        //border: 1px solid $primColor;

        .image__count {
          opacity: 1;
        }

        .item__name {
          color: $primColor;
        }
      }
    }
  }
}

.shop-filter {
  $headerHeight: 25px;

  .shop-filter__inner {

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $primColorNigger;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: green;
    }
  }

  &.sticky {
    position: sticky;
    display: block;
    top: $headerHeight;

    .shop-filter__inner {
      overflow-y: auto;
      height: calc(100vh - #{$headerHeight});
    }
  }

  .mse2_number_slider {
    .ui-slider-range {
      background-color: $primColorNigga;
    }

    .ui-slider-handle {
      border-radius: 50%;
      background-color: $primColor;
      border: none;
      width: 15px;
      height: 15px;

      &:focus {
        outline: none;
        border: 2px solid
      }
    }
  }

  .checkbox-items {
    .checkbox {
      display: flex;
      align-items: center;

      label {
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
      }

      strong {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  &__footer {
    margin-top: 15px;
    text-align: center;
  }

  &--expand {
    display: none;
  }
}

@media (max-width: 991px) {
  #mse2_mfilter {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    transition: left .2s ease-in-out;
    padding: 0;

    max-width: 45vw;

    .shop-filter {
      background-color: white;
      box-shadow: 5px 0 5px rgba(#000, .25);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 15px;
      height: 100vh;

      &.opened {

      }
    }

    .shop-filter--expand {
      display: block;
      background-color: $primColor;
      border: 1px solid $primColorNigga;
      border-radius: 0 5px 5px 0;
      color: white;
      font-size: 26px;
      padding: 7px 14px;
    }
  }
}

@media (max-width: 768px) {
  #mse2_mfilter {
    max-width: 100vw;
  }
}

.shop-sidebar__filter-item {
  .shop-sidebar-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .title-text {

    }

    .title-spoiler-toggle {
      font-size: 80%;
      color: $primColor;
      transition: color .2s ease-in-out;

      &:hover {
        color: $primColorNigga;
      }
    }
  }

  .checkbox-items {
    display: none;
  }
}

.shop-product {
  .single-product-list {
    width: 100%;
  }
}

.product-item__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}