#jGrowl {
  left: 10px;
  top: 10px;
  right: 10px;
  width: calc(100% - 20px);

  @media screen and (min-width: 768px){
    top: 0;
    right: 0;
    left: initial;
    max-width: 50vw;
  }

  @media screen and (min-width: 992px){
    max-width: 35vw;
  }

  @media screen and (min-width: 1400px){
    max-width: 20vw;
  }

  .jGrowl-closer {
    display: none !important;
    visibility: hidden;
    width: 0;
    height: 0;
  }

  .jGrowl-notification.alert {
    @extend %glass;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border: unset;
    border-bottom: 3px solid;
    border-bottom-color: $primColor;
    color: #000;
    font-size: 16px;
    padding: 15px 25px;
    width: auto;

    .jGrowl-message {
      display: flex;
      align-items: center;

      .message__icon {
        flex: 0
      }
    }

    .message__icon {
      color: $primColor;
      margin-right: 10px;
      font-size: 16px;
    }

    &.notify__theme-- {
      &error {
        border-bottom-color: $redColor;

        .message__icon {
          color: $redColor;
        }
      }

      &info {
        $yellowColor: #ffe600;
        border-bottom-color: $yellowColor;

        .message__icon {
          color: $yellowColor;
        }
      }
    }

    .jGrowl-close {
      font-size: 32px;
      line-height: 16px;
    }
  }
}