.html-sitemap {
  .expand {
    cursor: pointer;

    &:hover {
      color: $primColorNigga;
    }

    i {
      font-weight: 100;
    }
  }
  ul:not(.lvl-1) {
    position: relative;
    display: none;
    margin-left: 15px;

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: -15px;
      height: 100%;
      width: 1px;
      background-color: #a3a3a3;
    }
  }

  a {
    color: #333;
    font-size: 18px;

    &:hover, &:active {
      color: $primColorNigga;
    }
  }
}