.regular-price {
  .price__value, .price__label, .price__text {
    text-decoration: inherit;
  }
}

.shop-single-area {
  .product-btn .product-oneClickBuy--js {
    background-color: $primColor;
  }
}

.product-item {
  .add-to-links {
    display: flex;
    justify-content: center;
  }
}

.shop-single-content {
  .countdown {
    margin-bottom: 35px;

    .countdown-title {
      position: relative;
      font-size: 15px;
      color: #333;
    }

    .countdown-items {
      margin-left: -5px;
      margin-right: -5px;
      display: flex;
      flex-wrap: wrap;

      .countdown-col {
        padding-left: 5px;
        padding-right: 5px;
      }

      .single-countdown-item {
        background-color: #f2f4f6;
        width: 50px;
        padding: 15px 0;
        border-radius: 4px;
        text-align: center;
        margin-top: 30px;

        .count {
          display: block;
          font-size: 18px;
          font-weight: 500;
          color: #333;
          line-height: 22px;
        }

        .value {
          font-size: 13px;
          font-weight: 400;
          color: #333;
          line-height: 16px;
        }
      }

      .single-countdown-item.item-bg {
        background-color: #cf0016;

        .count {
          color: #fff;
        }

        .value {
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .countdown {
      .countdown-items {
        .single-countdown-item {
          width: 55px;

          .count {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.product-gallery {
  position: relative;

  .product__tags {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 10px;
    left: 10px;
    z-index: 2;

    .tag {
      padding: 2px 5px;
      border-radius: 3px;
      box-shadow: rgba(#000, .35);
      background-color: #fff;
      color: #333;
      margin-bottom: 5px;
      font-size: 12px;
      text-align: center;
    }

    .tag__new {
      background-color: $primColor;
      color: #fff;
    }

    .tag__sale {
      background-color: $redColor;
      color: #fff;
    }
  }

  .fotorama__stage {
    border: 1px solid #e6e6e6;
    margin-bottom: 15px;
  }

  .fotorama__arr {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    color: #333;
    transition: all .3s linear;
    background-color: #fff;

    &:hover, &:active {
      background-color: #0083c1;
      border-color: #0083c1;
      color: #fff;
    }
  }

  .fotorama__thumb {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
  }

  .fotorama__thumb-border {
    border-color: $primColor;
    border-radius: 4px;
  }
}